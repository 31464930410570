<template>
  <div class="replys">
    <div class="nav-topbar">
      <div class="mobiles-nav-list">
        <a class="nav-backs" @click="backs()"> 返回 </a>
      </div>
      <div class="nav-list">
        <a class="nav-backs" @click="backs()"> 返回 </a>
        <a class="nav-items">发布贴子 </a>
      </div>
    </div>
    <div class="centers-el">
      <!--
      <div class="centers-images">
        <el-upload
          class="upload-demo"
          name="file"
          action="https://sosunlive.com/api/common/upload"
          :on-preview="handlePreview"
          :on-remove="handleRemove"
          :file-list="fileListss"
          :before-upload="beforeUploadss"
          :on-success="handleSuccesss"
          list-type="picture"
          :limit="1"
          accept=".gif,.jpg,.png"
        >
          <el-button size="small" type="primary">点击上传付款码</el-button>
          <div slot="tip" class="el-upload__tip">
            只能上传jpg|png文件，且不超过100KB
          </div>
        </el-upload>
      </div>
      <div class="centers-images">
        <el-upload
          class="upload-demo"
          name="file"
          action="https://sosunlive.com/api/common/upload"
          :on-preview="handlePreview"
          :on-remove="handleRemove"
          :file-list="fileLists"
          :before-upload="beforeUploads"
          :on-success="handleSuccesss"
          accept=".mp4"
          :limit="1"
        >
          <el-button size="small" type="primary">点击上传视频</el-button>
          <div slot="tip" class="el-upload__tip">
            只能上传mp4文件，且不超过50mb
          </div>
        </el-upload>
      </div>-->

      <div class="editor-center">
        <el-card :body-style="{ padding: '10px' }">
          <div class="inputs">
            <el-input type="text" placeholder="添加标题" v-model="title">
            </el-input>
          </div>
          <div class="inputs" style="margin: 12px auto;">
            <el-radio-group v-model="category_id">
              <el-radio v-for="(val,index) in selectedItems" :label="val.id" :key="index">{{val.name}}</el-radio>
            </el-radio-group>            
          </div>
          
          <div class="inputs" style="margin: 12px auto;">
            <el-radio-group v-model="type" size="small">
             <el-radio label="4" border>提问</el-radio>
             <el-radio label="3" border>文章</el-radio>
          </el-radio-group>
          </div>
          <div class="inputs" v-if="tagList.length > 0">
            <el-tag type="danger" closable v-for="(val, index) in tagList" :key="index" style="margin-left: 8px;margin-top: 8px;"
              @close="slice(val)">{{ val }}
            </el-tag>
          </div>
          <div class="inputs">
            <el-input style="width: 80%;float: left;" type="text" placeholder="请输入标签" v-model="tagVal">
            </el-input>
            <el-button type="primary" style="width: 19%;float: right;" @click="addTag()">添加</el-button>
          </div>
          <!--
          <div class="inputs">
            <el-input type="text" placeholder="请输入云盘链接 (选填)" v-model="yunlink">
            </el-input>
          </div>
          <div class="inputs">
            <el-input type="text" placeholder="请输入云盘分享码 (选填)" v-model="yuncode">
            </el-input>
          </div>-->
          <!--
          <div class="inputs">
            <el-input type="text" placeholder="请输入链接网址" v-model="weblink">
            </el-input>
          </div>-->
          <vue-editor id="editor" class="editor" useCustomImageHandler @image-added="handleImageAdded" v-model="content"
            :editor-toolbar="customToolbar" placeholder="请输入帖子内容">
          </vue-editor>
        </el-card>
      </div>
      <div class="centers-images" v-if="!info.filmname">
        <el-upload class="upload-demo" name="file" action="https://sosunlive.com/api/common/upload"
        :data="additionalData"
        :headers="headers"
        :on-remove="(file, fileList) => {handleRemove(file, fileList, index)}" :file-list="fileList" :before-upload="beforeUpload"
          :on-success="handleSuccess" list-type="picture-card" :limit="1" accept=".gif,.jpg,.png">
          <i class="el-icon-plus"></i>
          <div slot="tip" class="el-upload__tip">只能上传1张主图</div>
        </el-upload>
      </div>
      <!--:limit="1"
      <div class="movie-body" v-if="info.filmname">
        <el-row>
          <el-col :span="24">
            <el-card :body-style="{ padding: '0px' }">
              <div class="filename-centers">
                <div class="filename-pic">
                  <el-image v-if="info.imagetype == 2" :src="baseUrls + info.file" class="image"
                    style="width: 99%;height: auto;margin: 0px auto;" />
                  <el-image v-if="info.imagetype == 1" :src="info.file" class="image"
                    style="width: 99%;height: auto;margin: 0px auto;" />
                </div>
                <div class="filename-content">
                  <div style="padding: 6px;">
                    <div class="font-text">{{ info.filmname }}</div>
                    <div class="font-text">年份：{{ info.year }}</div>
                    <div class="font-text">导演：{{ info.director }}</div>
                    <div class="font-text" v-if="info.screenwriter"> 编剧：{{
                      info.screenwriter }}
                    </div>
                    <div class="font-text">演员：{{ info.actress }}</div>
                    <div class="font-text" v-if="info.description">类型：{{
                      info.description }}
                    </div>
                    <div class="font-text">地区（国家）：{{ info.filmcountry }}
                    </div>
                  </div>
                </div>
              </div>
            </el-card>
          </el-col>
        </el-row>
      </div>
      -->
    </div>
    <div class="page-el">
      <div class="page-bottom">
        <div class="left">发布帖子 吸引更多粉丝关注！ O(∩_∩)O......</div>
        <div class="right">
          <el-button type="danger" @click="submit()" size="medium">提交</el-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import * as imageConversion from 'image-conversion'
import { VueEditor } from "vue2-editor";
import ApiCon from "../util/Api/index";
import axios from "axios";
export default {
  name: "Reply",
  data() {
    return {
      content: "",
      info: '',
      additionalData:{
          source:'www'
      },
      headers:{
        'Token':window.localStorage.getItem("token")
      },
      baseUrl: '',
      baseUrls: 'https://www.sosunlive.com',
      title: "",
      id: '',
      tag: "",
      category_id:'',
      type:'4',
      introduction:'',
      tagVal: '',
      tagList: [],
      yunlink: "",
      weblink: '',
      yuncode: "",
      selectedItems:[],
      fileListss: [],
      fileLists: [],
      fileList: [
        //{ name: 'food.jpeg', url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100' }
      ],
      customToolbar: [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }], //标题
        ["bold", "italic", "underline"], //加粗\斜体\下划线
        ["italic"], //斜体----也可以拆开来写，用于换位置顺序
        [{ align: "" }, { align: "center" }, { align: "right" }], //对齐
        [{ list: "ordered" }, { list: "bullet" }, { list: "check" }], //有序无序列表
        [{ background: [] }, { color: [] }], //背景色
        ["image", "link"], //图片、链接
        ["strike"], //作废（文字中间一横）
        ["underline"],
        ["clean"], //清楚格式（所谓去掉背景色这些花里胡巧）
        ["code"], //代码
        ["code-block"], //代码块
        //['video'] 视频
      ],
    };
  },
  components: {
    VueEditor,
  },
  created() {
    let self = this
    
    let category_ids = localStorage.getItem("sticks_category_ids")
    if (category_ids) {
      let selectedItems = category_ids.split(",")
      for(var i=0;i<selectedItems.length;i++){
        if(selectedItems[i]=='36'){
          let info={
            id:'36',
            name:'女神'
          }
          self.selectedItems.push(info)
        }else if(selectedItems[i]=='51'){
          let info={
            id:'51',
            name:'动作'
          }
          self.selectedItems.push(info)
        }else if(selectedItems[i]=='52'){
          let info={
            id:'52',
            name:'悬疑'
          }
          self.selectedItems.push(info)
        }else if(selectedItems[i]=='40'){
          let info={
            id:'40',
            name:'恐怖'
          }
          self.selectedItems.push(info)
        }else if(selectedItems[i]=='44'){
          let info={
            id:'44',
            name:'另类'
          }
          self.selectedItems.push(info)
        }else if(selectedItems[i]=='39'){
          let info={
            id:'39',
            name:'男神穿搭'
          }
          self.selectedItems.push(info)
        }else if(selectedItems[i]=='38'){
          let info={
            id:'38',
            name:'男神'
          }
          self.selectedItems.push(info)
        }else if(selectedItems[i]=='53'){
          let info={
            id:'53',
            name:'Kiss'
          }
          self.selectedItems.push(info)
        }else if(selectedItems[i]=='41'){
          let info={
            id:'41',
            name:'爱情'
          }
          self.selectedItems.push(info)
        }else if(selectedItems[i]=='43'){
          let info={
            id:'43',
            name:'感人'
          }
          self.selectedItems.push(info)
        }else if(selectedItems[i]=='37'){
          let info={
            id:'37',
            name:'女神穿搭'
          }
          self.selectedItems.push(info)
        }
      }
    }
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0
  },
  methods: {
    backs() {
      history.go(-1);
    },
    slice(val) {
      let self = this
      self.tagList.splice(val, 1)
    },
    addTag() {
      let self = this
      if (!self.tagVal) {
        self.$layer.msg("请先输入标签");
        //self.$message.error("请先输入标签");
        return;
      }
      for (let i = 0; i < self.tagList.length; i++) {
        if (self.tagList[i] == self.tagVal) {
          //self.$message.error("请不要输入相同标签");
          self.$layer.msg("请不要输入相同标签");
          return;
        }
      }
      self.tagList.push(self.tagVal)
      self.tagVal=''
    },
    getProductInfo() {
      let id = this.id;
      let self = this
      this.$api.mall.getDetail({ id: id }).then((res) => {
        //res.data.data.tag = res.data.data.tag.split(",")
        self.title = res.data.data.title
        self.info = res.data.data
        document.body.scrollTop = 0
        document.documentElement.scrollTop = 0
      });
    },
    handleSuccess(res, file, fileList) {
      let self=this
      if(self.fileList.length>0){
        self.$layer.msg("请不要上传多张主图");
        return
      }
      if (res.code == 1) {
        this.fileList.push({
          name: res.data.url,
          fileId: res.data.url,
          url: res.data.url,
        });
       // console.log(this.fileList)
        this.$emit("input", this.fileList);
      } else {
        fileList.filter((o) => o.uid != file.uid);
        this.$emit("input", this.fileList);
        this.$message({ message: res.msg, type: "error", duration: 1500 });
      }
    },
    handleSuccesss(res, file, fileList) {
      if (res.code == 1) {
        this.fileLists.push({
          name: res.data.url,
          fileId: res.data.url,
          url: res.data.url,
        });
        this.$emit("input", this.fileLists);
      } else {
        fileList.filter((o) => o.uid != file.uid);
        this.$emit("input", this.fileLists);
        this.$message({ message: res.msg, type: "error", duration: 1500 });
      }
    },
    handleSuccesss() {
      if (res.code == 1) {
        this.fileListss.push({
          name: res.data.url,
          fileId: res.data.url,
          url: res.data.url,
        });
        this.$emit("input", this.fileListss);
      } else {
        fileList.filter((o) => o.uid != file.uid);
        this.$emit("input", this.fileListss);
        this.$message({ message: res.msg, type: "error", duration: 1500 });
      }
    },
    beforeUpload(file) {
      //console.log(file, "99999")
      /**
      let isRightSize = file.size / (1024 * 500) < 0.1;
      if (!isRightSize) {
        this.$message.error(`文件大小超过500KB`);
        return false;
      }**/
      let self=this
      if(self.fileList.length>0){
        self.$layer.msg("请不要上传多张主图");
        return
      }
      //console.log(this.fileList,"ssbb")
      let types = ["image/jpeg", "image/jpg", "image/png"];
      const isImage = types.includes(file.type);
      // const isLtSize = file.size / 1024 / 1024 < 2;
      if (!isImage) {
          this.$message.error("上传图片只能是 JPG、JPEG、PNG 格式!");
          return false;
      }
      //图片压缩插件
      return new Promise((resolve) => {
          // 压缩到100KB,这里的100就是要压缩的大小,可自定义
          imageConversion.compressAccurately(file, 300).then(res => {
              console.log(res);
              let files = new File([res], file.name, { type: file.type });
              resolve(files)
          });
      })
    },
    beforeUploads(file) {
      //console.log(file, "99999")
      let isRightSize = file.size / (1024 * 1024) < 50;
      if (!isRightSize) {
        this.$message.error(`文件超过50MB`);
        return false;
      }
    },
    beforeUploadss(file) {
      //console.log(file, "99999")
      let isRightSize = file.size / (1024 * 500) < 0.1;
      if (!isRightSize) {
        this.$message.error(`文件大小超过100KB`);
        return false;
      }
    },
    submit() {
      let self = this;
      //console.log(self.fileList,"00999");
      if (!window.localStorage.getItem("token")) {
        self.$message.error("请先登录或者注册");
        return;
      }
      if (!self.title) {
        self.$layer.msg("请输入标题");
        return;
      }

      if (self.tagList.length == 0) {
        self.$layer.msg("请输入标签");
        return;
      }
      let tag = "";
      if (self.tagList.length > 0) {
        for (let a = self.tagList.length - 1; a >= 0; a--) {
          tag =tag + self.tagList[a] + ",";
        }
      }
      tag = tag.slice(0, tag.length - 1);
      if (self.fileList.length == 0) {
        self.$layer.msg("请上传主图");
        return;
      }

      if (self.fileList.length>1) {
        self.$layer.msg("请不要上传多张主图");
        return;
      }

      if (self.type=='') {
        self.$layer.msg("请选择内容类型");
        return;
      }

      //if (!self.introduction) {
        //self.$layer.msg("请填写内容简介");
        //self.$message.error("请填写内容");
        //return;
      //}

      if (!self.content) {
        self.$layer.msg("请填写内容");
        //self.$message.error("请填写内容");
        return;
      }
      let attachfile = "";
      if (self.fileLists.length > 0) {
        attachfile = self.fileLists[0].url;
      }
      /**
      if (!self.weblink && !self.yunlink) {
        self.$layer.msg("请填写分享网站接链或者网盘链接");
        //self.$message.error("请填写内容");
        return;
      }**/
      /**
      if(self.id==0){
        self.tag=self.title
      }else{
        self.tag=self.info.tag
      }**/
      this.$api.mall
        .stickAdd({
          maincontent:self.content,
          tag: tag,
          category_id:self.category_id,
          type:self.type,
          //mediaid: self.id,
          images: self.fileList[0].url,
          image:self.fileList[0].url,
          attachfile: attachfile,
          title: self.title,
          weblink: self.weblink,
          yunlink: self.yunlink,
          introduction:self.introduction,
          yuncode: self.yuncode,
          //paypic: self.fileListss[0].url
        })
        .then((res) => {
          console.log(res);
          if (res.code == 1) {
            self.$message.success("提交成功");
            self.content = "";
            self.fileList = [];
            self.fileLists = [];
            self.tagList=[];
            self.category_id=''
            self.introduction='';
            self.tag = "";
            self.title = "";
            self.yunlink = "";
            self.yuncode = "";
            document.body.scrollTop = 0
            document.documentElement.scrollTop = 0
          } else {
            self.$message.error("提交失败，请稍后再试");
          }
        });
    },
    handleRemove(file, fileList, index) {
      console.log(file, fileList)
      let self=this
      self.fileList.splice(index, 1)
    },
    handlePreview(file) {
      console.log(file);
    },
    async handleImageAdded(file, Editor, cursorLocation, resetUploader) {
      let self = this;
      // An example of using FormData
      // NOTE: Your key could be different such as:
      // formData.append('file', file)
      let types = ["image/jpeg", "image/jpg", "image/png"];
      const isImage = types.includes(file.type);
      // const isLtSize = file.size / 1024 / 1024 < 2;
      if (!isImage) {
          this.$message.error("上传图片只能是 JPG、JPEG、PNG 格式!");
          return false;
      }
      //图片压缩插件
    return new Promise((resolve) => {
        // 压缩到100KB,这里的100就是要压缩的大小,可自定义
        imageConversion.compressAccurately(file, 300).then(res => {
            console.log(res);
            let files = new File([res], file.name, { type: file.type });
            var formData = new FormData();
            formData.append("file", files);
            formData.append("source", 'www');
            axios({
              url: "https://sosunlive.com/api/common/upload",
              method: "POST",
              headers: {
                'Token':window.localStorage.getItem("token")
              },
              data: formData,
            })
              .then((result) => {
                let url = 'https://www.sosunlive.com'+result.data.data.url;
                Editor.insertEmbed(cursorLocation, "image", url);
                resetUploader();
              })
              .catch((err) => {
                console.log(err);
              });

          });
      })
    },
    updateData(e) {
      this.content = e;
    },
  },
};
</script>
<style lang="scss" scoped>
@media (max-width: 8000px) and (min-width: 1024px) {
  .replys {
    width: 100%;
    overflow: hidden;
    margin: 0px auto;
    padding: 0px;

    .centers-el {
      width: 630px;
      overflow: hidden;
      margin: 48px auto auto auto;
      padding-bottom: 108px;

      .movie-body {
        width: 100%;
        overflow: hidden;
        margin: 0px auto;
        background: #fff;
        border-radius: 2px;
        -webkit-box-shadow: 0 1px 3px rgb(18 18 18 / 10%);
        box-shadow: 0 1px 3px rgb(18 18 18 / 10%);
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        padding: 16px 15px 10px;

        .filename-centers {
          width: 100%;
          overflow: hidden;
          margin: 0px auto;
          box-sizing: border-box;
          padding: 10px;

          .filename-pic {
            width: 98px;
            overflow: hidden;
            float: left;
            margin: 0px auto;
          }

          .filename-content {
            width: calc(100% - 108px);
            overflow: hidden;
            margin: 0px auto;
            float: right;
            padding: 0px;
          }

          .font-text {
            width: 100%;
            line-height: 20px;
            font-size: 13px;
            overflow: hidden;
            /**
              width: 100%;
              line-height: 1.5;
              font-size: 12px;
              overflow: hidden;
              word-wrap: break-word; //自动换行
              word-break: break-all; //自动换行（兼容字母）
              overflow: hidden; //超出隐藏
              text-overflow: ellipsis; //溢出显示省略号
              display: -webkit-box;
              -webkit-line-clamp: 1; //显示3行
              -webkit-box-orient: vertical;
            */
          }
        }
      }

      .editor-center {
        width: 100%;
        background: #fff;
        box-sizing: border-box;
        padding: 10px;
        overflow: hidden;
        margin: 10px auto auto auto;

        .inputs {
          width: 100%;
          overflow: hidden;
          margin: 10px auto;
          .el-radio{
            display: inline-block;
            margin-top: 12px;
          }
        }
      }

      .centers-images {
        width: 100%;
        box-sizing: border-box;
        padding: 10px;
        overflow: hidden;
        margin: 0px auto;
      }
    }

    .page-el {
      width: 100%;
      box-sizing: border-box;
      padding: 10px;
      overflow: hidden;
      margin: 0px auto;
      bottom: 0px;
      background: #fff;
      left: 0px;
      box-shadow: 0 -3px 6px rgba(0, 0, 0, .06);
      z-index: 100;
      position: fixed;

      .page-bottom {
        width: 630px;
        overflow: hidden;
        margin: 0px auto;
        box-sizing: border-box;
        padding: 10px;

        .left {
          width: 50%;
          overflow: hidden;
          float: left;
          text-align: left;
        }

        .right {
          width: 50%;
          overflow: hidden;
          float: right;
          text-align: right;
        }
      }
    }

    .nav-topbar {
      width: 100%;
      overflow: hidden;
      background: #ffe url('/imgs/fade.png') top center repeat-x;
      position: fixed;
      top: 0px;
      left: 0px;
      z-index: 10;

      .mobiles-nav-list {
        display: none;
      }

      .nav-list {
        width: 630px;
        overflow: hidden;
        margin: 0px auto;

        .searchTexts {
          width: 50%;
          float: right;
          overflow: hidden;
          margin: 6px auto;
        }

        .nav-backs {
          text-decoration: none;
          padding: 1px;
          display: block;
          font-size: 14px;
          margin-left: 6px;
          font-weight: 400;
          overflow: hidden;
          color: maroon;
          line-height: 42px;
          text-align: center;
          float: left;
          cursor: pointer;
        }

        .nav-items {
          text-decoration: none;
          padding: 1px;
          display: block;
          font-size: 14px;
          margin-left: 6px;
          font-weight: 400;
          overflow: hidden;
          color: maroon;
          line-height: 42px;
          text-align: center;
          float: left;
        }

        .nav-backs:hover {
          color: red !important;
          text-decoration: underline;
        }
      }
    }
  }
}

@media (max-width: 1024px) and (min-width: 320px) {
  .replys {
    width: 100%;
    overflow: hidden;
    margin: 0px auto;
    padding: 0px;

    .page-el {
      width: 100%;
      box-sizing: border-box;
      padding: 10px;
      background: #fff;
      overflow: hidden;
      margin: 0px auto;
      bottom: 0px;
      left: 0px;
      box-shadow: 0 -3px 6px rgba(0, 0, 0, .06);
      z-index: 100;
      position: fixed;

      .page-bottom {
        width: 100%;
        overflow: hidden;
        margin: 0px auto;
        box-sizing: border-box;
        padding: 10px;

        .left {
          width: 50%;
          overflow: hidden;
          float: left;
          text-align: left;
        }

        .right {
          width: 50%;
          overflow: hidden;
          float: right;
          text-align: right;
        }
      }
    }

    .centers-el {
      width: 100%;
      box-sizing: border-box;
      overflow: hidden;
      margin: 48px auto auto auto;
      padding-bottom: 108px;

      .movie-body {
        width: 100%;
        overflow: hidden;
        margin: 0px auto;
        background: #fff;
        border-radius: 2px;
        -webkit-box-shadow: 0 1px 3px rgb(18 18 18 / 10%);
        box-shadow: 0 1px 3px rgb(18 18 18 / 10%);
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        padding: 16px 15px 10px;

        .filename-centers {
          width: 100%;
          overflow: hidden;
          margin: 0px auto;
          box-sizing: border-box;
          padding: 10px;

          .filename-pic {
            width: 98px;
            overflow: hidden;
            float: left;
            margin: 0px auto;
          }

          .filename-content {
            width: calc(100% - 108px);
            overflow: hidden;
            margin: 0px auto;
            float: right;
            padding: 0px;
          }

          .font-text {
            width: 100%;
            line-height: 18px;
            font-size: 13px;
            overflow: hidden;
            /**
              width: 100%;
              line-height: 1.5;
              font-size: 12px;
              overflow: hidden;
              word-wrap: break-word; //自动换行
              word-break: break-all; //自动换行（兼容字母）
              overflow: hidden; //超出隐藏
              text-overflow: ellipsis; //溢出显示省略号
              display: -webkit-box;
              -webkit-line-clamp: 1; //显示3行
              -webkit-box-orient: vertical;
            */
          }
        }
      }

      .editor-center {
        width: 98%;
        box-sizing: border-box;
        padding: 10px;
        background: #fff;
        overflow: hidden;
        margin: 0px auto;

        .inputs {
          width: 100%;
          overflow: hidden;
          margin: 10px auto;
          .el-radio{
            display: inline-block;
            margin-top: 12px;
          }
        }
      }

      .centers-images {
        width: 98%;
        overflow: hidden;
        box-sizing: border-box;
        padding: 10px;
        margin: 0px auto;
      }
    }

    .nav-topbar {
      width: 100%;
      overflow: hidden;
      position: fixed;
      top: 0px;
      left: 0px;
      z-index: 10;
      background: url('/imgs/fade.png') top center repeat-x;

      .mobiles-nav-list {
        width: 100%;
        overflow: hidden;
        box-sizing: border-box;
        padding: 5px;
        margin: 0px auto;

        .nav-backs {
          text-decoration: none;
          padding: 1px;
          display: block;
          font-size: 14px;
          margin-left: 10px;
          font-weight: 400;
          overflow: hidden;
          color: maroon;
          line-height: 30px;
          text-align: center;
          float: left;
        }
      }

      .nav-list {
        display: none;
      }
    }
  }
}
</style>