<template>
  <div class="index" id="shoplists">
    <div class="nav-topbar">
      <div class="mobiles-nav-list" v-show="showSea" style="padding: 2.5px 0px;">
        <el-input placeholder="好看帖子搜索" prefix-icon="el-icon-search" v-model="searchText" @input="init" size="big"
          style="width: 80%; float: left; margin-left: 2%">
        </el-input>
        <a class="nav-items-close" @click="showSearch"> 关闭 </a>
      </div>
      <div class="mobiles-nav-list">
        <div class="nav-list-centers" v-show="!showSea">
          <div class="nav-items"></div>
          <div class="nav-items">推荐</div>
          <!--
          <div class="nav-items">美女</div>
          <div class="nav-items">帅哥</div>
          <div class="nav-items">恐怖</div>
          <div class="nav-items">另类</div>
          <div class="nav-items">生活</div>
          <div class="nav-items">爱情</div>
          <div class="nav-items">励志</div>
          <div class="nav-items">美女时尚生活</div>
          <div class="nav-items">帅哥时尚生活</div>
          <div class="nav-items">讨论</div>
          <div class="nav-items" >讨论</div>
          <div class="nav-items" @click="NvoToCos()">电影推荐</div>-->
          <div class="nav-items" @click="showSearch">搜索</div>
        </div>
        <div class="nav-list-scroll">
          <div class="scroll-items" @click="itemActive('推荐', '推荐')" :class="{ 'active-font': '推荐' == activeIndex }">
            推荐
            <span class="line"></span>
          </div>
          <div class="scroll-items" @click="itemActive('关注', '关注')" :class="{ 'active-font': '关注' == activeIndex }">
            关注
            <span class="line"></span>
          </div>
          <div class="scroll-items" v-for="(val, index) in searchList" :key="index" @click="itemActive(val, index)"
            :class="{ 'active-font': index == activeIndex }">
            {{ val }}
            <span class="line"></span>
          </div>
          <div class="scroll-items" @click="itemActive('编辑', '编辑')" :class="{ 'active-font': '编辑' == activeIndex }">
            编辑
            <span class="line"></span>
          </div>
        </div>
      </div>
      <div class="nav-list">
        <div class="dict-top">
          <div class="hot_centers">
            <div class="hot_items" @click="init()">
              推荐
            </div>
            <div class="hot_items" @click="getFocusList()">
              关注
            </div>
            <!--
            <div class="hot_items" @click="NvoToCos()">
              电影推荐
            </div>-->
            <div class="hot_items" v-for="(val, index) in searchList" :key="index" @click="searchs(val)">
              <span>{{ val }}</span>
            </div>
            <div class="hot_items" @click="dialogFormVisible = true">
              <span>编辑</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--
    <div class="top_banner">
      <div class="swiper-box">
        <swiper class="swiper-container" :options="swiperOption">
          <swiper-slide v-for="(item, aindex) in slideList" :key="aindex">
            <img src="../../public/imgs/top_baner.jpg" alt="" />
          </swiper-slide>
          <div class="swiper-pagination" slot="pagination"></div>
        </swiper>
      </div>
    </div>
    -->
    <div class="container">
      <div class="search-centers">
        <el-input type="text" size="small" v-model="searchText" style="width:380px; float: left" clearable
          placeholder="好看帖子搜索"></el-input>
        <el-button type="warning" @click="init()" style="width: 68px; float: left; margin-left:12px;"
          size="small">搜索</el-button>
      </div>
      <div class="center-font-search" v-if="searchVal" @click="delhottext()">
        清除： {{ searchVal }}
      </div>
      <div class="new-font-search" v-for="(val, index) in list" :key="index" v-if="val.is_hot_show == true">
        <div class="hot-search-items" @click="navTo(val)">
          推荐帖子· · · · · ·{{ val.title }}
          <span class="close" @click="delHotItem(val.id, index)"></span>
        </div>
      </div>

      <div class="new-font-search" v-show="rand == ''">
        按顺序最新更新 · · · · · ·
      </div>
      <div class="new-font-search" v-show="rand == 1">
        随机推荐 · · · · · ·
      </div>
      <div class="left">
        <!--
        <div class="list" v-if="showTags">
          <el-tag type="warning" closable  effect="dark" :disable-transitions="true" @close="showTags = false">
            最新更新XXXX电影
          </el-tag>
        </div>-->
        <div class="list">
          <div class="items" v-for="(vals, index) in list" :key="index">
            <div class="user-centers">
              <el-avatar :src="baseUrls + vals.user.avatar" shape="square" :size="28" fit="contain"
                style="float: left;"></el-avatar>
              <div class="user-nickname">
                {{ vals.user.nickname }}
              </div>
              <div class="createtime">
                {{ vals.createtime }}
              </div>
              <div class="focuscenter" v-if="focus == 1">
                <el-button round size="mini" @click="savefollow(vals, index)" v-if="!vals.follow_show"
                  icon="el-icon-star-off">关注</el-button>
                <el-button round size="mini" @click="delfollow(vals, index)" v-if="vals.follow_show" type="warning"
                  icon="el-icon-star-off">已关注</el-button>
              </div>
              <div class="focuscenter" v-if="focus == 2">
                <!--@click="navToPage('/StickReply')"-->{{ vals.user_id }}
                <el-button round size="mini" @click="navToPage('/mySpaces/' + vals.user_id+'/'+vals.rand_token)"
                  icon="el-icon-star-off">查看空间</el-button>
              </div>
            </div>
            <div class="item-contents" v-if="vals.type == 4">
              <div class="items-centers">
                <div class="image-centers" v-if="vals.images" @click="navTo(vals)" style="width: 100%;max-height:368px;">
                  <el-image :src="baseUrls + vals.image" style="width:100%;height: auto;"></el-image>
                </div>
                <div class="text">
                  <div class="items-title" @click="navTo(vals)">
                    <span>{{ vals.title }}</span>
                  </div>
                </div>
              </div>
              <div class="center-tag">
                <el-tag size="mini" type="warning" class="item-tags" v-for="(val, index) in vals.tag" :key="index">{{
        val
      }}</el-tag>
              </div>
              <div class="center-tag">
                <el-button v-if="!vals.collect_show" icon="el-icon-star-off" round size="mini"
                  @click="saves(vals, index)">收藏</el-button>
                <el-button v-if="vals.collect_show" type="warning" icon="el-icon-star-off" round size="mini"
                  @click="del(vals, index)"> 已收藏</el-button>
              </div>
            </div>
            <div class="item-contents" v-if="vals.type == 1">
              <div class="items-centers">
                <div class="image-centers" v-if="vals.images" @click="navTo(vals)">
                  <el-image v-for="(val, index) in vals.images" :key="index" :src="baseUrls + val"
                    style="width:100%;height: auto;"></el-image>
                  <!--lazy-->
                </div>
                <div class="text">
                  <div class="items-title" @click="navTo(vals)">
                    <span>{{ vals.title }}</span>
                  </div>
                </div>
                <div class="body-centers"
                  :style="{ height: vals.shows == true ? '128px' : '', overflow: vals.shows == false ? 'hidden' : '' }">
                  <div v-html="vals.maincontent"></div>
                </div>
                <div class="mores" @click="shows(vals, index)" v-show="vals.shows">查看更多</div>
                <div class="packup" @click="shows(vals, index)" v-show="!vals.shows">收起</div>
              </div>
              <div class="center-tag">
                <el-tag size="mini" type="warning" class="item-tags" v-for="(val, index) in vals.tag" :key="index">{{
        val
      }}</el-tag>
              </div>
              <div class="center-tag">
                <el-button v-if="!vals.collect_show" icon="el-icon-star-off" round size="mini"
                  @click="saves(vals, index)">收藏</el-button>
                <el-button v-if="vals.collect_show" type="warning" icon="el-icon-star-off" round size="mini"
                  @click="del(vals, index)"> 已收藏</el-button>
              </div>
            </div>
            <div class="item-contents" v-if="vals.type == 2">
              <div class="pic-centers" @click="navTo(vals)" v-show="vals.shows">
                <div class="text">
                  <div class="items-title" @click="navTo(vals)">
                    <span>{{ vals.title }}</span>
                  </div>
                </div>
                <el-image v-for="(val, indexs) in vals.images" :key="indexs" :src="baseUrls + val" lazy
                  style="width:32.3%;height: auto;float: left;margin-left: 1%;"></el-image>
              </div>
              <div class="pic-contents" v-show="!vals.shows">
                <div class="text">
                  <div class="items-title" @click="navTo(vals)">
                    <span>{{ vals.title }}</span>
                  </div>
                </div>
                <el-image v-for="(val, indexss) in vals.images" :key="indexss" :src="baseUrls + val"
                  style="width:100%;height: auto;"></el-image>
              </div>
              <div class="mores" @click="shows(vals, index)" v-show="vals.shows">查看更多</div>
              <div class="packup" @click="shows(vals, index)" v-show="!vals.shows">收起</div>
              <div class="center-tag">
                <el-tag size="mini" type="warning" class="item-tags" v-for="(val, index) in vals.tag" :key="index">{{
        val }}</el-tag>
              </div>
              <div class="center-tag">
                <el-button v-if="vals.collect_show == false" icon="el-icon-star-off" round size="mini"
                  @click="saves(vals, index)">收藏</el-button>
                <el-button v-if="vals.collect_show == true" type="warning" icon="el-icon-star-off" round size="mini"
                  @click="del(vals, index)"> 已收藏</el-button>
              </div>
            </div>
            <div class="item-contents" v-if="vals.type == 3">
              <div class="video-centers">
                <div class="text">
                  <div class="items-title" @click="navTo(vals)">
                    <span>{{ vals.title }}</span>
                  </div>
                </div>
                <video-player class="video-player vjs-custom-skin" ref='videoPlayer' :playsinline="true"
                  :options="vals.playerOptions">
                </video-player>
                <div class="text-click">
                  <div class="items-title">播放列表：</div>
                  <div class="items-title" v-for="(item, indexsss) in vals.attachfiles" :key="indexsss"
                    @click="player(item, index)">
                    <span>{{ vals.title }}_{{ indexsss + 1 }}</span>
                  </div>
                </div>
              </div>
              <div class="center-tag">
                <el-tag size="mini" type="warning" class="item-tags" v-for="(val, index) in vals.tag" :key="index">{{
        val }}</el-tag>
              </div>
              <div class="center-tag">
                <el-button v-if="!vals.collect_show" icon="el-icon-star-off" round size="mini"
                  @click="saves(vals, index)">收藏</el-button>
                <el-button v-if="vals.collect_show" type="warning" icon="el-icon-star-off" round size="mini"
                  @click="del(vals, index)"> 已收藏</el-button>
              </div>
            </div>
          </div>
        </div>
        <div class="el-page">
          <div class="left-page">
            <el-pagination background @current-change="handleCurrentChange" :current-page="currentPage"
              :page-size="pageSize" layout="total,prev,pager,next" :total="total">
            </el-pagination>
          </div>
          <div class="right-page" v-if="focus == 1">
            <el-button type="warning" plain size="small" style="width: 80%;margin: 0px auto;display: block;"
              @click="handleCurrentChange('rand')">随机推荐</el-button>
          </div>
        </div>
      </div>
      <div class="right">
        <div class="login-centers">
          主题切换：
          <el-button circle style="background: #ffe url('/imgs/fade.png') top center repeat-x;"
            @click="setTheme(1)"></el-button>
          <el-button circle style="background: #fff;" @click="setTheme(2)"></el-button>
        </div>
        <div class="login-centers">
          <div class="login-title" v-show="active == 1">登录</div>
          <div class="login-title" v-show="active == 2">注册</div>
          <el-form v-show="active == 1" ref="loginForm" autocomplete="on" label-position="left">
            <el-form-item prop="account">
              <el-input ref="account" v-model="loginForm.account" placeholder="请输入手机号" name="account" type="text"
                tabindex="1" autocomplete="on" size="large">
              </el-input>
            </el-form-item>
            <el-form-item prop="password">
              <el-input ref="password" v-model="loginForm.password" placeholder="请输入密码" name="password" type="password"
                tabindex="1" autocomplete="on" size="large">
              </el-input>
            </el-form-item>
            <el-button type="warning" size="small" @click.native.prevent="handleLogin">登录</el-button>
            <el-button type="warning" size="small" @click.native.prevent="isResgister">注册</el-button>
          </el-form>
          <el-form v-show="active == 3" ref="userInfo" autocomplete="on" label-position="center">
            <el-form-item prop="avatar" align="center" style="margin-bottom: 0px; text-align: center">
              <img :src="'https://www.sosunlive.com' + userInfo.avatar"
                style="width: 68px; height: auto; border-radius: 3px;cursor: pointer;"
                @click="navToPage('/mySpaces/' + userInfo.id)" />
            </el-form-item>
            <el-form-item prop="nickname" align="center" bottom="0px" style="margin-bottom: 0px;">
              <span>{{ userInfo.nickname }} | 积分：{{ userInfo.score }} | 关注：{{ userInfo.focus_count }}人 |
                粉丝：{{ userInfo.fans_count }}人</span>
            </el-form-item>
            <!--
            <el-form-item prop="score" align="center" bottom="0px">
                <span>积分：{{ userInfo.score }}</span>
              </el-form-item>-->
            <el-form-item style="margin-bottom: 0px;" align="center" bottom="0px">
              <el-button type="warning" @click="active = 4" size="small" style="margin-left: 0px">重置个人信息</el-button>
              <el-button type="warning" @click="logout" size="small" style="margin-left: 10px">注销</el-button>
              <!--
              <el-button type="primary" icon="el-icon-s-comment" @click="navToRes()" size="mini"
                style="margin-left: 0px">我要发资源</el-button>
              <el-button type="primary" icon="el-icon-s-comment" @click="navToMes()" size="mini"
                style="margin-left: 10px">我的资源</el-button>
              <el-button type="primary" icon="el-icon-s-comment" @click="navToReply()" size="mini"
                style="margin-left: 10px">回复我的</el-button>-->
            </el-form-item>
          </el-form>
          <el-form v-show="active == 4" ref="userForm" autocomplete="on" label-position="left">
            <el-form-item prop="username">
              <el-input ref="username" v-model="userForm.username" placeholder="请输入昵称" name="username" type="text"
                tabindex="1" autocomplete="on" size="large">
              </el-input>
            </el-form-item>
            <el-form-item prop="password">
              <el-input ref="password" v-model="userForm.password" placeholder="请输入密码" name="password" type="password"
                tabindex="1" autocomplete="on" size="large">
              </el-input>
            </el-form-item>
            <!---
            <el-form-item prop="address">
              <el-input ref="address" v-model="userForm.address" placeholder="请输入地址" name="address" type="text"
                tabindex="1" autocomplete="on" size="large">
              </el-input>
            </el-form-item>-->
            <el-form-item prop="password">
              <el-upload class="upload-demo" name="file" action="https://sosunlive.com/api/common/upload"
                :headers="headers" :on-preview="handlePreview" :on-remove="handleRemove" :file-list="fileLists"
                :on-success="handleSuccesss" list-type="picture" :before-upload="beforeUpload" :limit="1"
                accept=".gif,.jpg,.png">
                <el-button size="small" type="warning">点击上传头像</el-button>
                <div slot="tip" class="el-upload__tip">
                  只能上传jpg/png文件，且不超过500kb
                </div>
              </el-upload>
            </el-form-item>
            <el-button type="warning" size="small" @click.native.prevent="setUserInfo">提交</el-button>
            <el-button type="warning" @click="active = 3" size="small">返回</el-button>
          </el-form>

          <el-form v-show="active == 2" ref="registerForm" autocomplete="on" label-position="left">
            <el-form-item prop="nickname">
              <el-input ref="nickname" v-model="registerForm.username" placeholder="请输入昵称" name="nickname" type="text"
                tabindex="1" autocomplete="on" size="large">
              </el-input>
            </el-form-item>
            <el-form-item prop="account">
              <el-input ref="account" v-model="registerForm.mobile" placeholder="请输入手机号" name="account" type="text"
                tabindex="1" autocomplete="on" size="large">
              </el-input>
            </el-form-item>
            <el-form-item prop="authcode">
              <el-input v-model="registerForm.authcode" placeholder="请输入验证码" @blur="blurcheck">
                <el-button slot="suffix" size="mini" style="border: none" @click="sendcode" :disabled="!show">
                  获取验证码
                  <span v-show="!show" class="count">({{ count }}s)</span>
                </el-button>
              </el-input>
            </el-form-item>
            <el-form-item prop="password">
              <el-input ref="password" v-model="registerForm.password" placeholder="请输入密码" name="password"
                type="password" tabindex="1" autocomplete="on" size="large">
              </el-input>
            </el-form-item>
            <!--
            <el-form-item prop="address">
              <el-input ref="address" v-model="registerForm.address" placeholder="请输入地址" name="address" type="text"
                tabindex="1" autocomplete="on" size="large">
              </el-input>
            </el-form-item>-->
            <!--
            <el-form-item prop="image">
              <el-upload class="upload-demo" name="file" action="https://sosunlive.com/api/common/upload"
                :on-preview="handlePreview" :on-remove="handleRemove" :file-list="fileList" :on-success="handleSuccess"
                list-type="picture">
                <el-button size="small" type="warning">点击上传</el-button>
                <div slot="tip" class="el-upload__tip">
                  只能上传jpg/png文件，且不超过500kb
                </div>
              </el-upload>
            </el-form-item>-->
            <el-button type="warning" size="small" @click.native.prevent="handResgister">注册</el-button>
            <el-button type="warning" size="small" @click.native.prevent="isResgister">登录</el-button>
          </el-form>
        </div>
        <div class="user-centers">
          <div class="user-text" v-show="active == 1">请先登陆/注册</div>
          <div class="user-info" v-show="active == 3">
            <div class="item" @click="navToPage('/Reply')">
              <img src="../../public/imgs/zi1.png" style="width: 38px;height: auto;" />
              <div class="item-name">我要发帖</div>
            </div>
            <div class="item" @click="navToPage('/Posted')">
              <img src="../../public/imgs/zi2.png" style="width: 38px;height: auto;" />
              <div class="item-name">我的帖子</div>
            </div>
            <div class="item" @click="navToPage('/Commit')">
              <img src="../../public/imgs/zi9.png" style="width: 38px;height: auto;" />
              <div class="item-name">我的评论</div>
            </div>
            <div class="item" @click="navToPage('/StickReply')">
              <img src="../../public/imgs/zi3.png" style="width: 38px;height: auto;" />
              <div class="item-name">回复我的</div>
            </div>
            <div class="item" @click="navToPage('myCollects')">
              <img src="../../public/imgs/zi5.png" style="width: 38px;height: auto;" />
              <div class="item-name">我的收藏</div>
            </div>
            <div class="item" @click="navToPage('/myFollows')">
              <img src="../../public/imgs/zi6.png" style="width: 38px;height: auto;" />
              <div class="item-name">我的关注</div>
            </div>
            <div class="item" @click="navToPage('/myFans')">
              <img src="../../public/imgs/zi7.png" style="width: 38px;height: auto;" />
              <div class="item-name">我的粉丝</div>
            </div>
            <!--
            <div class="item" @click="NvoToCos()" v-if="userInfo.level==2" >
              <img src="../../public/imgs/movie.png" style="width: 38px;height: auto;" />
              <div class="item-name">影视库</div>
            </div>
             -->
          </div>
        </div>
        <div class="hot_lists" v-if="hotList.length > 0">
          <div class="hot_top_tites">最新提问 · · · · · ·</div>
          <HotItem :hotList="hotList" :hotTotal="hotTotal" @handleHotCurrentChange="handleHotCurrentChange"></HotItem>
        </div>
        <div class="web_text">
          <div class="font-centers">
            <div class="font">浙ICP备17001033号-1</div>
            <div class="font">恐怖悬疑另类电影添加群主微信</div>
            <div class="wxinfo">
              <img src="../../public/imgs/qunzhu_1.jpg" width="100%" height="auto">
            </div>
            <div class="font">Kiss爱情电影添加群主微信</div>
            <div class="wxinfo">
              <img src="../../public/imgs/qunzhu_2.jpg" width="100%" height="auto">
            </div>
            <div class="font">本站遵守国家法律法规 如发现非法信息</div>
            <div class="font">请立即联系我们(QQ：3556766711)</div>
            <div class="font">临海市暖心日用百货商行 版权所有</div>
            <div class="font">Copyright © 2024 All rights reserved.</div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog title="编辑" :visible.sync="dialogFormVisible" :width="width" :before-close="handleClose">
      <div slot="title" class="header-title" :style="{ 'background': '#f2f2f2', 'color': '#6a6a6a' }">
        <div style="padding:20px 20px;">编辑</div>
      </div>
      <el-row :gutter="20">
        <el-col :span="24">
          <div class="sex-centers">
            <div class="sex-item">性别:</div>
            <div class="sex-m" @click="changSex('1')" :class="{ 'active-m': sex == '1' }">男</div>
            <div class="sex-n" @click="changSex('2')" :class="{ 'active-n': sex == '2' }">女</div>
          </div>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="24">
          <div class="movie-centers">
            <div class="movie-item">类型:</div>
            <div class="right-movie">
              <div class="movie-m" :class="{ 'active': selectedItems.includes('36') }" @click="toggleSelection('36')">女神
              </div>
              <div class="movie-m" :class="{ 'active': selectedItems.includes('51') }" @click="toggleSelection('51')">动作
              </div>
              <div class="movie-m" :class="{ 'active': selectedItems.includes('52') }" @click="toggleSelection('52')">悬疑
              </div>
              <div class="movie-m" :class="{ 'active': selectedItems.includes('40') }" @click="toggleSelection('40')">恐怖
              </div>
              <div class="movie-m" :class="{ 'active': selectedItems.includes('44') }" @click="toggleSelection('44')">另类
              </div>
              <div class="movie-m" :class="{ 'active': selectedItems.includes('39') }" @click="toggleSelection('39')">
                男神穿搭
              </div>
              <div class="movie-m" :class="{ 'active': selectedItems.includes('38') }" @click="toggleSelection('38')">男神
              </div>
              <div class="movie-m" :class="{ 'active': selectedItems.includes('53') }" @click="toggleSelection('53')">
                Kiss
              </div>
              <!--
              <div class="movie-m" :class="{'active':selectedItems.includes('42')}" @click="toggleSelection('42')">生活</div>-->
              <div class="movie-m" :class="{ 'active': selectedItems.includes('41') }" @click="toggleSelection('41')">爱情
              </div>
              <div class="movie-m" :class="{ 'active': selectedItems.includes('43') }" @click="toggleSelection('43')">感人
              </div>
              <div class="movie-m" :class="{ 'active': selectedItems.includes('37') }" @click="toggleSelection('37')">
                女神穿搭
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="24">
          <div class="navCenters">
            <div class="nav-item">标签:</div>
            <div class="right-nav">
              <div class="navItems" v-for="(val, isndexs) in searchList" :key="isndexs" @click="deletes(val)">
                {{ val }}
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="24">
          <el-input type="text" tabindex="1" autocomplete="on" size="large" v-model="navText"
            placeholder="请输入标签"></el-input>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="24">
          <el-button type="warning" @click="addsNav()" style="width: 100%;">添加</el-button>
        </el-col>
        <el-col :span="24">
          <el-button type="danger" @click="handleClose()" style="width: 100%;margin-top: 10px;">关闭</el-button>
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
import ApiCon from "../util/Api/index";
import HotItem from '@/components/HotQues';

export default {
  name: "index",
  data() {
    return {
      searchList: [
        "恐怖",
        "悬疑",
        "另类",
        "Kiss",
        "感人",
        "爱情",
      ],
      showTags: true,

      hotList: [],
      hotTotal: 1,
      rand: '',
      value: "",
      headers: {
        'Token': window.localStorage.getItem("token")
      },
      sex: '',
      focus: 1,
      movie: 1,
      activeIndex: "推荐",
      searchVal: "",
      width: "40%",
      //options: [],
      slideList: [
        {
          id: "43",
          img: "../../public/imgs/top_baner.jpg",
        },
      ],
      playerOptions: {
        playbackRates: [0.5, 1.0, 1.5, 2.0], // 可选的播放速度
        autoplay: false, // 如果为true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 是否视频一结束就重新开始。
        preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: 'zh-CN',
        aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [{
          type: "video/mp4", // 类型
          src: '' // url地址
        }],
        poster: '', // 封面地址
        notSupportedMessage: '', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true, // 当前时间和持续时间的分隔符
          durationDisplay: true, // 显示持续时间
          remainingTimeDisplay: false, // 是否显示剩余时间功能
          fullscreenToggle: true // 是否显示全屏按钮
        }
      },
      loading: false,
      meType: 1,
      navText: "",
      searchText: "",
      total: 1,
      pageCount: 3,
      dialogFormVisible: false,
      active: 1,
      currentPage: 1, // 当前页码
      pageSize: 18, // 每页显示的行数
      baseUrl: "",
      baseUrls: "https://www.sosunlive.com",
      timer: null, //计时器
      code: "", //验证码
      count: "", //倒计时
      show: true, //控制按钮
      loginForm: {
        account: "",
        password: "",
      },
      userForm: {
        password: "",
        address: "",
        avatar: "",
      },
      userInfo: {
        avatar:
          "/avatar.png",
        nickname: "您好",
        score: 0,
        fans_count: 0,
        focus_count: 0,
      },
      registerForm: {
        account: "",
        mobile: "",
        password: "",
        address: "",
        code: "",
        avatar: "",
        authcode: "",
        username: "",
      },
      category_ids: '',
      showSea: false,
      selectedItems: [],
      topScroll: 0,
      list: [],
      hotList: [],
      fileList: [
        //{ name: 'food.jpeg', url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100' }
      ],
      fileLists: [
        // { name: 'food.jpeg', url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100' }
      ],
    };
  },
  components: {
    HotItem
  },
  filters: {

  },
  mounted() {
    this.ApiCon = new ApiCon(this.$store);
    this.ApiConUrl = this.ApiCon.getApiUrl();
  },
  created() {
    this.ApiCon = new ApiCon(this.$store);

    //this.$layer.msg('你好')
    // localStorage.setItem("hotTexts","Kiss")
    //localStorage.setItem("hotTexts","附身")
    this.ApiConUrl = this.ApiCon.getApiUrl();
    this.baseUrl = this.ApiConUrl;
    let sex = localStorage.getItem("sticks_sex")
    let category_ids = localStorage.getItem("sticks_category_ids")
    if (category_ids) {
      this.category_ids = category_ids
      this.selectedItems = category_ids.split(",")
    } else {
      //this.$message.success("请勾选自己喜欢的类型，关闭即可"); // 提示信息
      // this.dialogFormVisible=true
      this.category_ids = '30'
    }
    if (sex) {
      this.sex = sex
      if (this.sex == 1) {
        this.category_ids = this.category_ids + ',36,37'
      } else {
        this.category_ids = this.category_ids + ',38,39'
      }
    }
    let val = localStorage.getItem("hotTexts");
    if (val) {
      this.searchs(val);
      this.getHotLists()
    } else {
      this.init();
      this.getHotLists()
    }
    if (window.screen.width > 1280) {
      this.width = "40%";
    } else {
      this.width = "95%";
    }
    let userInfo = window.localStorage.getItem("userinfo");
    if (userInfo) {
      this.active = 3;
      this.userInfo = JSON.parse(userInfo);
    } else {
      //this.$router.push("/Login");
    }
    if (window.localStorage.getItem("token")) {
      this.getUserInfos();
    }
    let searchList = window.localStorage.getItem("indexListsss");
    if (searchList) {
      this.searchList = JSON.parse(searchList);
    } else {
      window.localStorage.setItem("indexListsss", JSON.stringify(this.searchList));
    }

  },
  methods: {
    handleHotCurrentChange(val) {
      this.hotList = [];
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
      let type = ''
      if (this.category_ids == '30') {
        type = '30'
      } else {
        type = this.category_ids + ',30'
      }
      this.$api.mall
        .getHotSticksList({
          page: val,
          type: type,
          set: '4',
          limit: this.pageSize,
        })
        .then((ress) => {
          let res = ress.data;
          for (var i = 0; i < res.data.data.length; i++) {
            res.data.data[i].shows = true
            res.data.data[i].tag = res.data.data[i].tag.split(",");
          }
          this.hotTotal = res.data.total
          this.hotList = res.data.data
        });
    },
    getHotLists() {
      this.hotList = [];
      let type = ''
      if (this.category_ids == '30') {
        type = '30'
      } else {
        type = this.category_ids + ',30'
      }
      this.$api.mall
        .getHotSticksList({
          page: 1,
          type: type,
          set: '4',
          limit: this.pageSize,
        })
        .then((ress) => {
          let res = ress.data;
          for (var i = 0; i < res.data.data.length; i++) {
            res.data.data[i].shows = true
            res.data.data[i].tag = res.data.data[i].tag.split(",")
          }
          this.hotTotal = res.data.total
          this.hotList = res.data.data
        });
    },
    showCollect(val) {
      let collects_ids = localStorage.getItem("collects_ids")
      if (collects_ids) {
        collects_ids = collects_ids.split(',')
      }
      if (Array.isArray(collects_ids)) {
        return collects_ids.some(function (arrVal) {
          return arrVal === val.id.toString();
        })
      } else {
        return false
      }
    },
    showFollow(val) {
      let follow_ids = localStorage.getItem("follow_ids")
      let follow_token = localStorage.getItem("follow_token")
      //follow_token
      if (follow_ids) {
        follow_ids = follow_ids.split(',')
        follow_ids.forEach((item, index) => {
          follow_ids[index]=(parseInt(follow_ids[index])-parseInt(follow_token))/parseInt(follow_token)
          //console.log(follow_ids[index])
        })
      }
      val.user_id=(parseInt(val.user_id)-parseInt(val.rand_token))/parseInt(val.rand_token)
      //console.log(val.user_id)
      if (Array.isArray(follow_ids)) {
        return follow_ids.some(function (arrVal) {
          return arrVal.toString() === val.user_id.toString();
        })
      } else {
        return false
      }
    },
    showIsHot(val) {
      let stick_list_hot = localStorage.getItem('stick_list_hot')
      if (stick_list_hot) {
        stick_list_hot = stick_list_hot.split(',')
      }
      if (Array.isArray(stick_list_hot)) {
        if (val.is_hot == 1) {
          return stick_list_hot.some(function (arrVal) {
            return arrVal === val.id.toString();
          })
        } else {
          return false
        }
      } else if (val.is_hot == 1) {
        return true
      } else {
        return false
      }
    },
    delHotItem(id, index) {
      let self = this
      let stick_list_hot = localStorage.getItem('stick_list_hot')
      if (stick_list_hot) {
        self.list[index].is_hot_show = false
        stick_list_hot = stick_list_hot + ',' + id
      } else {
        self.list[index].is_hot_show = false
        stick_list_hot = id
      }
      localStorage.setItem('stick_list_hot', stick_list_hot)
    },
    showHotItem(id) {
      let stick_list_hot = localStorage.getItem('stick_list_hot')
      if (stick_list_hot) {
        stick_list_hot = stick_list_hot.split(',')
      }
      if (Array.isArray(stick_list_hot)) {
        if (stick_list_hot.includes(id.toString())) {
          return false
        } else {
          return true
        }
      } else {
        return true
      }
    },
    savefollow(val, index) {
      let self = this
      self.$api.mall.setFocus({
        focus_id: val.focus_id,
        rand_token:val.rand_token
      }).then((res) => {
        console.log(res);
        if (res.code == 1) {
          let collects = res.data
          //localStorage.setItem('follow_ids', collects)
          
          localStorage.removeItem("follow_ids");  
          localStorage.removeItem("follow_token");  
          localStorage.setItem('follow_ids', collects.focus)
          localStorage.setItem('follow_token', collects.focus_token)
          let change = self.list[index]
          change.follow_show = true
          this.$set(this.list, index, change)
          self.$message.success("关注成功");
        } else {
          self.$message.error(res.msg);
        }
      });
    },
    delfollow(val, index) {
      let self = this
      self.$api.mall.delFocus({
        'id': val.user_id
      }).then((res) => {
        console.log(res);
        if (res.code == 1) {
          let collects = res.data
          //localStorage.setItem('follow_ids', collects)
        
          localStorage.removeItem("follow_ids");  
          localStorage.removeItem("follow_token");  
          localStorage.setItem('follow_ids', collects.focus)
          localStorage.setItem('follow_token', collects.focus_token)
          let change = self.list[index]
          change.follow_show = false
          this.$set(this.list, index, change)
          self.$message.success("取消关注");
        } else {
          self.$message.error("取消失败");
        }
      });
    },
    del(val, index) {
      let self = this
      self.$api.mall.delcollects({
        'sticks_id': val.id
      }).then((res) => {
        console.log(res);
        if (res.code == 1) {
          let collects = res.data
          localStorage.setItem('collects_ids', collects)
          // self.list[index].collect_show = false
          let change = self.list[index]
          change.collect_show = false
          this.$set(this.list, index, change)
          self.$message.success("取消收藏");
        } else {
          self.$message.error("取消失败");
        }
      });
    },
    saves(val, index) {
      let self = this
      //setcollects
      self.$api.mall.setcollects({
        sticks_id: val.id
      }).then((res) => {
        console.log(res);
        if (res.code == 1) {
          let collects = res.data
          localStorage.setItem('collects_ids', collects)
          //self.list[index].collect_show = true
          let change = self.list[index]
          change.collect_show = true
          this.$set(this.list, index, change)
          self.$message.success("收藏成功");
        } else {
          self.$message.error("收藏失败");
        }
      });
    },
    handleClose() {
      this.dialogFormVisible = false
      location.reload()
    },
    delhottext() {
      localStorage.setItem("hotTexts", "");
      this.searchVal = "";
      this.activeIndex = '推荐'
      this.init();
    },
    changSex(val) {
      let self = this
      self.sex = val
      localStorage.setItem("sticks_sex", val)
    },
    changMovie(val) {
      let self = this
      self.movie = val
    },
    toggleSelection(item) {
      this.category_ids = ''
      // 检查已选中项数组中是否包含当前点击项
      const selectedIndex = this.selectedItems.indexOf(item);
      if (selectedIndex === -1) {
        // 如果当前项未被选中，则把点击项添加到数组中
        this.selectedItems.push(item);
      } else {
        // 如果当前项已被选中，则从已选中项数组中移除
        this.selectedItems.splice(selectedIndex, 1);
      }
      console.log(this.selectedItems)

      for (var i = 0; i < this.selectedItems.length; i++) {
        this.category_ids = this.category_ids + this.selectedItems[i] + ','
      }
      let newStr = this.category_ids.substr(0, this.category_ids.length - 1);
      this.category_ids = newStr
      localStorage.setItem("sticks_category_ids", this.category_ids)
    },
    player(item, index) {
      //let video='videoPlayer'+index
      //this.$refs.videoPlayer0.player.play()
      this.list[index]['playerOptions'] =
      {
        playbackRates: [0.5, 1.0, 1.5, 2.0], // 可选的播放速度
        autoplay: true, // 如果为true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 是否视频一结束就重新开始。
        preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: 'zh-CN',
        aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [{
          type: "video/mp4", // 类型
          src: 'https://www.sosunlive.com' + item// url地址
        }],
        notSupportedMessage: '', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true, // 当前时间和持续时间的分隔符
          durationDisplay: true, // 显示持续时间
          remainingTimeDisplay: false, // 是否显示剩余时间功能
          fullscreenToggle: true // 是否显示全屏按钮
        }
      }
    },
    handleSuccess(res, file, fileList) {
      if (res.code == 1) {
        this.fileList.push({
          name: res.data.url,
          fileId: res.data.url,
          url: res.data.url,
        });
        this.$emit("input", this.fileList);
      } else {
        fileList.filter((o) => o.uid != file.uid);
        this.$emit("input", this.fileList);
        this.$message({ message: res.msg, type: "error", duration: 1500 });
      }
    },
    handleSuccesss(res, file, fileList) {
      if (res.code == 1) {
        this.fileLists.push({
          name: res.data.url,
          fileId: res.data.url,
          url: res.data.url,
        });
        this.$emit("input", this.fileLists);
      } else {
        fileList.filter((o) => o.uid != file.uid);
        this.$emit("input", this.fileLists);
        this.$message({ message: res.msg, type: "error", duration: 1500 });
      }
    },
    beforeUpload(file) {
      let isRightSize = file.size / (1024 * 500) < 0.5;
      if (!isRightSize) {
        this.$message.error(`文件大小超过500KB`);
        return false;
      }
    },
    getUserInfos() {
      let self = this;
      this.$api.mall
        .getUserInfo({})
        .then((ress) => {
          if(ress.data==''){
            localStorage.removeItem("token");
            localStorage.removeItem("userinfo");
            self.active = 1;
          }
          self.active = 3;
          self.userInfo.score = ress.data.score
          self.userInfo.fans_count = ress.data.fans_count
          self.userInfo.focus_count = ress.data.focus_count
          localStorage.setItem('follow_ids', ress.data.focus)
          localStorage.setItem('follow_token', ress.data.focus_token)
          localStorage.setItem('collects_ids', ress.data.collects)
          localStorage.removeItem("userinfo")
          localStorage.setItem("userinfo", JSON.stringify(ress.data))
        })
        .catch((err) => {
          console.log(err)
          //self.$message.error(err.data.msg); // 提示信息
          if (err.data.code == "401"||err.data==null) {
            localStorage.removeItem("token");
            localStorage.removeItem("userinfo");
            self.active = 1;
          }
        });
    },
    navToFollow() {
      let self = this;
      self.$router.push("/myFollows");
    },
    showSearch() {
      let self = this;
      if (self.showSea == false) {
        self.showSea = true;
      } else {
        self.showSea = false;
      }
    },
    setTheme(val) {
      let self = this
      if (val == 1) {
        document.body.className = "";
        window.localStorage.setItem("theme", '1')
      } else {
        document.body.className = "white";
        window.localStorage.setItem("theme", '2')
      }
    },
    logout() {
      let self = this
      window.localStorage.removeItem("token")
      window.localStorage.removeItem("userinfo")
      self.active = 1
    },
    navToPage(val) {
      let self = this;
      self.$router.push(val);
    },
    navTo(vals) {
      let self = this;
      self.$router.push("/Receipt/" + vals.id);
    },
    NvoToCos() {
      let self = this;
      if (!window.localStorage.getItem("token")) {
        self.$message.error('请先登录')
        return
      }
      let userInfo = window.localStorage.getItem("userinfo");
      this.userInfo = JSON.parse(userInfo);
      if (this.userInfo.level < 2) {
        self.$message.error('请添加站长微信，发送手机号码给站长解锁，放心我们不会泄露个人信息！')
        return
      }
      self.$router.push("/community");
    },
    meShow(index) {
      let self = this;
      self.meType = index;
      self.init();
    },
    deletes(val) {
      let self = this;
      let searchList = window.localStorage.getItem("indexListss");
      if (searchList) {
        //self.searchList = searchList;
        let index = self.searchList.indexOf(val);
        if (index !== -1) {
          self.searchList.splice(index, 1);
        }
        window.localStorage.setItem("indexListss", JSON.stringify(self.searchList));
        self.$message.success("设置成功，请刷新页面");
      } else {
        self.$message.error("请先添加");
      }
    },
    addsNav() {
      let self = this;
      if (!self.navText) {
        this.$message.error("请输入标签");
        return;
      }
      let searchList = window.localStorage.getItem("indexListss");
      if (searchList) {
        let array = self.searchList;
        array.push(self.navText);
        //JSON.stringify()
        window.localStorage.setItem("indexListss", JSON.stringify(array));
        self.$message.success("设置成功，请刷新页面");
      } else {
        let array = [
          "恐怖",
          "悬疑",
          "另类",
          "Kiss",
          "感人",
          "爱情",
        ];
        array.push(self.navText);
        window.localStorage.setItem("indexListss", JSON.stringify(array));
        self.$message.success("设置成功，请刷新页面");
      }
    },
    itemActive(item, index) {
      let self = this;
      if (index != '编辑') {
        self.activeIndex = index
      }
      if (item == "推荐") {
        self.searchs("")
        return
      }
      if (item == "编辑") {
        self.dialogFormVisible = true
        return
      }
      if (item == "关注") {
        self.getFocusList()
        return
      }
      self.searchs(item);
    },
    searchs(text) {
      this.searchVal = text;
      localStorage.setItem("hotTexts", text);
      this.currentPage = 1;
      this.searchText = "";
      this.list = [];
      let type = ''
      if (this.category_ids == '30') {
        type = '30'
      } else {
        type = this.category_ids + ',30'
      }
      this.rand = ''
      this.$api.mall
        .getListSticks({
          page: this.currentPage,
          limit: this.pageSize,
          tag: this.searchVal,
          type: type
        })
        .then((ress) => {
          let res = ress.data
          for (var i = 0; i < res.data.data.length; i++) {
            res.data.data[i].shows = true
            res.data.data[i].focus_id = res.data.data[i].user_id
            res.data.data[i].tag = res.data.data[i].tag.split(",")
            if (res.data.data[i].type == 3) {
              let files = res.data.data[i].attachfiles
              if (files == '') {
                files = res.data.data[i].files
              }
              if (files) {
                let attachfiles = files.split(",")
                res.data.data[i].attachfiles = attachfiles
                //attachfile playerOptions
                console.log(res.data.data[i].attachfiles)
                res.data.data[i]['playerOptions'] =
                {
                  playbackRates: [0.5, 1.0, 1.5, 2.0], // 可选的播放速度
                  autoplay: false, // 如果为true,浏览器准备好时开始回放。
                  muted: false, // 默认情况下将会消除任何音频。
                  loop: false, // 是否视频一结束就重新开始。
                  preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
                  language: 'zh-CN',
                  aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
                  fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
                  sources: [{
                    type: "video/mp4", // 类型
                    src: 'https://www.sosunlive.com' + attachfiles[0]// url地址
                  }],
                  poster: 'https://www.sosunlive.com' + res.data.data[i].image, // 封面地址
                  notSupportedMessage: '', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
                  controlBar: {
                    timeDivider: true, // 当前时间和持续时间的分隔符
                    durationDisplay: true, // 显示持续时间
                    remainingTimeDisplay: false, // 是否显示剩余时间功能
                    fullscreenToggle: true // 是否显示全屏按钮
                  }
                }
              }
            }
          }
          this.total = res.data.total
          this.list = res.data.data
          this.list.forEach((item, index) => {
            item.is_hot_show = this.showIsHot(item)
            item.collect_show = this.showCollect(item)
            item.follow_show = this.showFollow(item)
          })
          document.body.scrollTop = 0
          document.documentElement.scrollTop = 0
        });
    },
    sendcode() {
      if (!this.timer) {
        this.count = 60;
        //sendAliDaYuAuthCode
        let self = this;
        if (!self.registerForm.mobile) {
          self.$message.error("请先输入手机号码");
          return;
        }
        var reg = /^[1][3,4,5,7,8,9][0-9]{9}$/;
        if (!reg.test(self.registerForm.mobile)) {
          self.$message.msg("请填写正确的手机号码");
          return;
        }
        self.$api.mall
          .sendAliDaYuAuthCode({ phone: self.registerForm.mobile })
          .then((res) => {
            if (res.code == 1) {
              this.show = false;
              self.$message.success("发送成功");
              this.timer = setInterval(() => {
                if (this.count > 0 && this.count <= 60) {
                  this.count--;
                } else {
                  this.show = true;
                  clearInterval(this.timer); // 清除定时器
                  this.timer = null;
                }
              }, 1000);
            } else {
              self.$message.error("发送失败")
            }
          });
      }
    },
    //在输入完成后可以判断一下
    blurcheck() {
      if (this.registerForm.code.length < 6) {
        return false;
      } else {
        if (this.registerForm.code && this.registerForm.code != "") {
          // this.$message.error("验证码错误")
          return false
        }
      }
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      //this.ApiCon = new ApiCon(this.$store)
      //this.ApiConUrl = this.ApiCon.getApiUrl()
      //this.ApiCon.addPage(val)
      //console.log(this.ApiCon.gets('pages'))
      //this.$router.push("/cart");
      let self = this
      if (self.focus == 2) {
        self.currentPage = val
        self.getFocusList()
      }
      document.body.scrollTop = 0
      document.documentElement.scrollTop = 0
      this.currentPage = val
      this.rand = ''
      if (val == 'rand') {
        this.rand = 1
        this.currentPage = 0
      } else {
        this.rand = ''
        this.ApiCon.addcommunityPage(val)
      }
      let type = ''
      if (this.category_ids == '30') {
        type = '30'
      } else {
        type = this.category_ids + ',30'
      }
      this.list = [];
      this.$api.mall
        .getListSticks({
          page: this.currentPage,
          limit: this.pageSize,
          title: this.searchText,
          tag: this.searchVal,
          type: type,
          rand: this.rand
        })
        .then((ress) => {
          let res = ress.data
          for (var i = 0; i < res.data.data.length; i++) {
            res.data.data[i].shows = true
            //res.data.data[i].is_hot_show =this.showIsHot(res.data.data[i])
            // res.data.data[i].collect_show = this.showCollect(res.data.data[i])
            res.data.data[i].tag = res.data.data[i].tag.split(",")
            res.data.data[i].focus_id = res.data.data[i].user_id
            if (res.data.data[i].type == 3) {
              let files = res.data.data[i].attachfiles
              if (files == '') {
                files = res.data.data[i].files
              }
              if (files) {
                let attachfiles = files.split(",");
                res.data.data[i].attachfiles = attachfiles
                //attachfile playerOptions
                res.data.data[i]['playerOptions'] =
                {
                  playbackRates: [0.5, 1.0, 1.5, 2.0], // 可选的播放速度
                  autoplay: false, // 如果为true,浏览器准备好时开始回放。
                  muted: false, // 默认情况下将会消除任何音频。
                  loop: false, // 是否视频一结束就重新开始。
                  preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
                  language: 'zh-CN',
                  aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
                  fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
                  sources: [{
                    type: "video/mp4", // 类型
                    src: 'https://www.sosunlive.com' + attachfiles[0]// url地址
                  }],
                  poster: 'https://www.sosunlive.com' + res.data.data[i].image, // 封面地址
                  notSupportedMessage: '', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
                  controlBar: {
                    timeDivider: true, // 当前时间和持续时间的分隔符
                    durationDisplay: true, // 显示持续时间
                    remainingTimeDisplay: false, // 是否显示剩余时间功能
                    fullscreenToggle: true // 是否显示全屏按钮
                  }
                }
              }
            }
          }
          this.total = res.data.total
          this.list = res.data.data
          this.list.forEach((item, index) => {
            item.is_hot_show = this.showIsHot(item)
            item.collect_show = this.showCollect(item)
            item.follow_show = this.showFollow(item)
          })
        });
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    getFocusList() {
      //getFocusSticksList
      let self = this
      if(!localStorage.getItem('token')){
        self.$message.error("请先登录");
        return
      }
      self.focus = 2
      let follow_ids = localStorage.getItem("follow_ids")
      let follow_token = localStorage.getItem("follow_token")
      this.list = []
      this.$api.mall
        .getFocusSticksList({
          page: this.currentPage,
          limit: this.pageSize,
          flist: follow_ids,
          flist_token:follow_token
        })
        .then((ress) => {
          let res = ress.data
          for (var i = 0; i < res.data.data.length; i++) {
            res.data.data[i].shows = true
            res.data.data[i].tag = res.data.data[i].tag.split(",")
            if (res.data.data[i].type == 3) {
              let files = res.data.data[i].attachfiles
              if (files == '') {
                files = res.data.data[i].files
              }
              if (files) {
                let attachfiles = files.split(",")
                res.data.data[i].attachfiles = attachfiles
                //attachfile playerOptions
                res.data.data[i]['playerOptions'] =
                {
                  playbackRates: [0.5, 1.0, 1.5, 2.0], // 可选的播放速度
                  autoplay: false, // 如果为true,浏览器准备好时开始回放。
                  muted: false, // 默认情况下将会消除任何音频。
                  loop: false, // 是否视频一结束就重新开始。
                  preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
                  language: 'zh-CN',
                  aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
                  fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
                  sources: [{
                    type: "video/mp4", // 类型
                    src: 'https://www.sosunlive.com' + attachfiles[0]// url地址
                  }],
                  poster: 'https://www.sosunlive.com' + res.data.data[i].image, // 封面地址
                  notSupportedMessage: '', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
                  controlBar: {
                    timeDivider: true, // 当前时间和持续时间的分隔符
                    durationDisplay: true, // 显示持续时间
                    remainingTimeDisplay: false, // 是否显示剩余时间功能
                    fullscreenToggle: true // 是否显示全屏按钮
                  }
                }
              }
            }
          }
          this.total = res.data.total
          this.list = res.data.data
          this.list.forEach((item, index) => {
            item.is_hot_show = this.showIsHot(item)
            item.collect_show = this.showCollect(item)
            //item.follow_show = this.showFollow(item)
          })
          document.body.scrollTop = 0
          document.documentElement.scrollTop = 0
        });

    },
    // 初始化
    init() {
      //this.searchVal = ''
      let self = this
      self.focus = 1
      this.currentPage = 1;
      if (this.ApiCon.gets("communitypages")) {
        this.currentPage = this.ApiCon.gets("communitypages");
      }
      this.list = [];
      let type = ''
      if (this.category_ids == '30') {
        type = '30'
      } else {
        type = this.category_ids + ',30'
      }
      this.$api.mall
        .getListSticks({
          page: this.currentPage,
          limit: this.pageSize,
          title: this.searchText,
          type: type
        })
        .then((ress) => {
          let res = ress.data
          for (var i = 0; i < res.data.data.length; i++) {
            res.data.data[i].shows = true
            res.data.data[i].focus_id = res.data.data[i].user_id
            res.data.data[i].tag = res.data.data[i].tag.split(",")
            if (res.data.data[i].type == 3) {
              let files = res.data.data[i].attachfiles
              if (files == '') {
                files = res.data.data[i].files
              }
              if (files) {
                let attachfiles = files.split(",")
                res.data.data[i].attachfiles = attachfiles
                //attachfile playerOptions
                res.data.data[i]['playerOptions'] =
                {
                  playbackRates: [0.5, 1.0, 1.5, 2.0], // 可选的播放速度
                  autoplay: false, // 如果为true,浏览器准备好时开始回放。
                  muted: false, // 默认情况下将会消除任何音频。
                  loop: false, // 是否视频一结束就重新开始。
                  preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
                  language: 'zh-CN',
                  aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
                  fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
                  sources: [{
                    type: "video/mp4", // 类型
                    src: 'https://www.sosunlive.com' + attachfiles[0]// url地址
                  }],
                  poster: 'https://www.sosunlive.com' + res.data.data[i].image, // 封面地址
                  notSupportedMessage: '', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
                  controlBar: {
                    timeDivider: true, // 当前时间和持续时间的分隔符
                    durationDisplay: true, // 显示持续时间
                    remainingTimeDisplay: false, // 是否显示剩余时间功能
                    fullscreenToggle: true // 是否显示全屏按钮
                  }
                }
              }
            }
          }
          this.total = res.data.total
          this.list = res.data.data
          this.list.forEach((item, index) => {
            item.is_hot_show = this.showIsHot(item)
            item.collect_show = this.showCollect(item)
            item.follow_show = this.showFollow(item)
          })
          document.body.scrollTop = 0
          document.documentElement.scrollTop = 0
        });
    },
    //登录
    handleLogin() {
      let self = this;
      if (!self.loginForm.account) {
        self.$message.error("请输入手机号码");
        return;
      }
      if (!self.loginForm.password) {
        self.$message.error("请输入密码");
        return;
      }
      self.$api.mall.login(self.loginForm).then((res) => {
        if (res.code == 1) {
          self.active = 3;
          self.userInfo = res.data.userinfo;
          window.localStorage.setItem(
            "userinfo",
            JSON.stringify(res.data.userinfo)
          );
          window.localStorage.setItem("token", res.data.userinfo.token);
          self.$message.success("登陆成功");
        } else {
          self.$message.error("登陆失败");
        }
      });
    },
    setUserInfo() {
      let self = this;
      if (!self.userForm.username) {
        self.$message.error("请输入昵称");
        return;
      }
      if (self.fileLists.length > 0) {
        self.userForm.avatar = self.fileLists[0].url;
      }
      self.$api.mall.setUserInfo(self.userForm).then((res) => {
        console.log(res);
        if (res.code == 1) {
          self.active = 1;
          window.localStorage.removeItem('userinfo')
          window.localStorage.removeItem('token')
          /**
          window.localStorage.setItem(
            "userinfo",
            JSON.stringify(res.data.userinfo)
          );
          window.localStorage.setItem("token", res.data.userinfo.token);
          **/
          self.$message.success("修改成功,请重新登陆");
        } else {
          self.$message.error("修改失败");
        }
      });
    },
    handResgister() {
      let self = this;
      /**
      if (self.fileList.length > 0) {
        self.registerForm.avatar = self.fileList[0].url;
      }**/
      if (!self.registerForm.mobile) {
        self.$message.error("请输入手机号码");
        return;
      }
      if (!self.registerForm.username) {
        self.$message.error("请输入昵称");
        return;
      }
      if (!self.registerForm.password) {
        self.$message.error("请输入密码");
        return;
      }
      if (self.registerForm.password.length <= 6) {
        self.$message.error("您的密码过于简单");
        return;
      }
      if (!self.registerForm.authcode) {
        self.$message.error("请输入验证码");
        return;
      }
      self.$api.mall.register(self.registerForm).then((res) => {
        console.log(res);
        if (res.code == 1) {
          self.active = 3;
          self.userInfo = res.data.userinfo;
          window.localStorage.setItem(
            "userinfo",
            JSON.stringify(res.data.userinfo)
          );
          window.localStorage.setItem("token", res.data.userinfo.token);
          self.$message.success("注册成功");
        } else {
          self.$message.error("注册失败");
        }
      });
    },
    shows(vals, index) {
      let self = this;
      if (self.list[index].shows == true) {
        self.topScroll = document.documentElement.scrollTop
        self.list[index].shows = false;
      } else {
        document.documentElement.scrollTop = self.topScroll
        self.list[index].shows = true;
      }
    },
    isResgister() {
      let self = this;
      if (self.active == 1) {
        self.active = 2;
      } else {
        self.active = 1;
      }
    },
  },
};
</script>

<style lang='scss' scoped>
@media (max-width: 8000px) and (min-width: 1024px) {
  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: #fff;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #e67843;
  }

  .index {
    width: 100%;
    overflow: hidden;
    margin: 0px auto;

    .el-row {
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .add-text {
      width: 100%;
      overflow: hidden;
      margin-top: 12px;
    }

    .movie-centers {
      width: 100%;
      overflow: hidden;
      padding: 0px;
      margin: 0px auto;
      box-sizing: border-box;

      .active {
        background: #e94608;
        color: #fff !important;
        border-right: 1px solid #f2f2f2 !important;
      }

      .right-movie {
        float: right;
        width: 81%;
        overflow: hidden;
        padding: 0px;
        margin: 0px auto;
        box-sizing: border-box;
      }

      .movie-item {
        width: 19%;
        text-align: center;
        overflow: hidden;
        color: maroon;
        float: left;
        line-height: 32px;
        margin-top: 12px;
        overflow: hidden;
      }

      .movie-m {
        width: calc(20% - 1px);
        text-align: center;
        overflow: hidden;
        cursor: pointer;
        color: maroon;
        float: left;
        border-right: 1px solid #fff;
        line-height: 32px;
        margin-top: 12px;
        overflow: hidden;
      }
    }

    .sex-centers {
      width: 100%;
      overflow: hidden;
      padding: 0px;
      margin: 0px auto;
      box-sizing: border-box;

      .sex-item {
        width: 19%;
        color: maroon;
        overflow: hidden;
        line-height: 32px;
        text-align: center;
        float: left;
      }

      .active-m {
        background: url(../../public/imgs/man.png) no-repeat bottom #e94608 !important;
        background-size: 68px !important;
        color: #fff;
      }

      .active-n {
        background: url(../../public/imgs/nv.png) no-repeat bottom #e94608 !important;
        background-size: 68px !important;
        color: #fff;
      }

      .sex-m {
        width: 88px;
        text-align: center;
        overflow: hidden;
        cursor: pointer;
        height: 88px;
        background: url(../../public/imgs/man.png) no-repeat bottom;
        //#f0f2f7;
        background-size: 68px;
        float: left;

        border: 1px solid maroon;
        border-radius: 6px;
      }

      .sex-n {
        width: 88px;
        text-align: center;
        overflow: hidden;
        cursor: pointer;
        height: 88px;
        margin-left: 12px;
        background: url(../../public/imgs/nv.png) no-repeat bottom;
        background-size: 68px;
        float: left;
        border: 1px solid maroon;
        border-radius: 6px;
      }
    }

    .navCenters {
      width: 100%;
      overflow: hidden;
      padding: 0px;
      margin: 0px auto;
      box-sizing: border-box;

      .right-nav {
        float: right;
        width: 81%;
        overflow: hidden;
        padding: 0px;
        margin: 0px auto;
        box-sizing: border-box;
      }

      .nav-item {
        width: 19%;
        margin-top: 12px;
        color: maroon;
        overflow: hidden;
        line-height: 32px;
        text-align: center;
        float: left;
      }

      .navItems {
        width: 20%;
        cursor: pointer;
        text-overflow: ellipsis;
        -webkit-line-clamp: 1;
        margin-top: 12px;
        white-space: nowrap;
        -webkit-box-orient: vertical;
        color: maroon;
        overflow: hidden;
        line-height: 32px;
        text-align: center;
        float: left;
        border-radius: 8px;
        background: url(../../public/imgs/deletes.png) no-repeat right;
        background-size: 18px;
      }
    }

    .nav-topbar {
      width: 100%;
      overflow: hidden;
      background: #fff;
      position: fixed;
      top: 0px;
      left: 0px;
      z-index: 10;

      .mobiles-nav-list {
        display: none;
      }

      .nav-list {
        width: 100%;
        overflow: hidden;
        margin: 0px auto;
        padding: 0px;
        z-index: 10;
        background: #ffe url('/imgs/fade.png') top center repeat-x;
        color: maroon;
        position: fixed;
        box-sizing: border-box;

        .dict-top {
          width: 980px;
          overflow: hidden;
          margin: 0px auto;
          padding: 0px;
          box-sizing: border-box;

          .hot_centers {
            /**
            width: 100%;
            line-height: 40px;
            overflow: hidden;
            margin: 0px auto;
            padding: 0px;**/
            width: 100%;
            white-space: nowrap;
            overflow-y: auto;
            box-sizing: border-box;
            padding: 0;
            margin: 0 auto;

            .hot_items:nth-child(1) {
              margin-left: 0%;
            }

            .hot_items {
              /**
              margin-left: 12px;
              margin-right: 8px;
              float: left;
              text-align: center;
              display: block;
              cursor: pointer;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;**/
              margin-left: 12px;
              padding-right: 8px;
              cursor: pointer;
              display: block;
              line-height: 40px;
              overflow: hidden;
              text-align: center;
              display: inline-block;
            }

            .hot_items span:hover {
              color: red !important;
            }
          }
        }
      }
    }

    /**
    .top_banner {
      width: 100%;
      overflow: hidden;
      box-sizing: border-box;
      margin: 48px auto auto auto;
      background: #fff;
    }

    .swiper-box {
      .swiper-container {
        width: 100%;
        overflow: hidden;

        img {
          width: 100%;
          height: auto;
        }
      }
    }**/

    .container {
      width: 980px;
      overflow: hidden;
      margin: 60px auto;
      line-height: 28px;

      .search-centers {
        width: 100%;
        overflow: hidden;
        box-sizing: border-box;
        margin: 12px auto;
        padding: 0px;
      }

      .new-font-search {
        width: 100%;
        cursor: pointer;
        overflow: hidden;
        margin: 0px auto;
        text-align: left;
        color: #072;
        font-size: 16px;

        .hot-search-items:hover {
          border: 1px solid #ebb563;
        }

        .hot-search-items {
          background-color: #fff;
          border-color: #f5dab1;
          color: #e6a23c;
          width: 680px;
          padding: 0 10px;
          overflow: hidden;
          position: relative;
          line-height: 30px;
          font-size: 14px;
          border-width: 1px;
          border-style: solid;
          border-radius: 4px;
          margin-top: 4px;
          box-sizing: border-box;

          .close {
            position: absolute;
            bottom: 0px;
            right: 0px;
            width: 30px;
            height: 30px;
            background: url(../../public/imgs/deletes.png) no-repeat center;
            background-size: 25px 25px;
          }
        }
      }

      .center-font-search {
        width: 100%;
        cursor: pointer;
        overflow: hidden;
        margin: 0px auto;
        text-align: left;
        color: #072;
        font-size: 16px;
      }

      .center-font-search:hover {
        text-decoration: underline;
      }

      .center-font {
        width: 100%;
        overflow: hidden;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        margin: 0px auto;
        cursor: pointer;
        padding-top: 10px;
        padding-bottom: 10px;
        text-align: center;
        font-size: 16px;
      }

      .left {
        width: 680px;
        overflow: hidden;
        float: left;
        box-sizing: border-box;

        .el-page {
          width: 100%;
          overflow: hidden;
          padding: 0px;
          -webkit-box-sizing: border-box;
          box-sizing: border-box;
          margin: 0px auto;

          .left-page {
            width: 50%;
            float: left;
            overflow: hidden;
            padding: 0px;
          }

          .right-page {
            width: 50%;
            float: left;
            overflow: hidden;
            padding: 0px;
          }
        }

        .list {
          width: 100%;
          overflow: hidden;
          margin: auto auto 10px auto;

          .items {
            width: 100%;
            overflow: hidden;
            margin: 0px auto;
            border-top: 1px dashed #f0f2f7;
            background: #fff;
            box-sizing: border-box;
            padding: 16px 15px 10px;

            .item-contents {
              width: 100%;
              box-sizing: border-box;
              overflow: hidden;
              margin: 0px auto;
              padding: 0px;
            }

            .text-click {
              width: 100%;
              box-sizing: border-box;
              overflow: hidden;
              margin: 12px auto;
              background: #f1f2f3;
              position: relative;
              border-radius: 6px;
              padding: 12px;
            }

            .pic-centers {
              width: 100%;
              box-sizing: border-box;
              overflow: hidden;
              margin: 12px auto;
              padding: 0px;
              height: 128px;
            }

            .pic-contents {
              width: 100%;
              box-sizing: border-box;
              overflow: hidden;
              margin: 12px auto;
              padding: 0px;
            }

            .video-centers {
              width: 100%;
              box-sizing: border-box;
              overflow: hidden;
              //padding: 10px;
              margin: 12px auto;
            }

            .body-centers {
              width: 100%;
              overflow: hidden;
              margin: 0px auto 12px auto;
              padding: 0px;
            }

            .user-nickname {
              font-synthesis: style;
              color: #37a;
              line-height: 28px;
              float: left;
              box-sizing: border-box;
              overflow: hidden;
              padding-right: 10px;
              padding-left: 10px;
            }

            .focuscenter {
              float: left;
              color: #999;
              box-sizing: border-box;
              overflow: hidden;
            }

            .createtime {
              float: left;
              color: #999;
              font-size: 12px;
              line-height: 28px;
              box-sizing: border-box;
              overflow: hidden;
              padding-right: 10px;
              padding-left: 10px;
            }

            .center-tag {
              width: 100%;
              overflow: hidden;
              margin: 8px auto;

              .item-tags {
                margin-left: 8px;
              }

              .item-tags:nth-child(1) {
                margin-left: 0px;
              }
            }

            .mores {
              width: 100%;
              height: auto;
              overflow: hidden;
              line-height: 28px;
              cursor: pointer;
              padding: 0px;
              line-height: inherit;
              background-color: transparent;
              color: #37a;
            }

            .packup {
              display: inline-block;
              height: auto;
              cursor: pointer;
              padding: 0;
              line-height: inherit;
              background-color: transparent;
              line-height: 28px;
              margin-left: 4px;
              color: #175199;
            }

            .items-contents {
              width: 100%;
              overflow: hidden;
              margin: 0px auto;
              padding: 0px;
            }

            .items-title {
              color: #37a;
              font-weight: 500;
              font-synthesis: style;
              font-size: 16px;
              cursor: pointer;
              width: 100%;
              word-wrap: break-word; //自动换行
              word-break: break-all; //自动换行（兼容字母）
              overflow: hidden; //超出隐藏
              text-overflow: ellipsis; //溢出显示省略号
              display: -webkit-box;
              -webkit-line-clamp: 1; //显示3行
              -webkit-box-orient: vertical;
            }

            .items-title span:hover {
              background: #37a;
              color: #fff;
            }

            .items-centers {
              width: 100%;
              overflow: hidden;
              margin: 0px auto;
              padding: 0px;

              .image-centers {
                width: 100%;
                overflow: hidden;
                cursor: pointer;
                padding: 0px;
                box-sizing: border-box;
                margin-top: 12px;
              }

              img {
                //float: left;

                width: 100%;
                overflow: hidden;
                text-align: center;
                cursor: pointer;
              }

              .text {
                //float: right;
                //width: calc(100% - 128px);
                width: 100%;
                overflow: hidden;
                margin: 0px auto;
                padding: 0px;

                .font-text {
                  color: #333;
                  font-size: 14px;
                  font-weight: 500;
                  font-synthesis: style;
                  cursor: pointer;
                  width: 100%;
                  overflow: hidden;
                  word-wrap: break-word; //自动换行
                  word-break: break-all; //自动换行（兼容字母）
                  overflow: hidden; //超出隐藏
                  text-overflow: ellipsis; //溢出显示省略号
                  display: -webkit-box;
                  -webkit-line-clamp: 1; //显示3行
                  -webkit-box-orient: vertical;
                }
              }
            }
          }
        }
      }

      .right {
        width: 290px;
        overflow: hidden;
        float: right;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        margin: 0px auto;

        .user-centers {
          width: 100%;
          overflow: hidden;
          box-sizing: border-box;
          margin: 10px auto;
          padding: 0px;
          border-radius: 2px;
          -webkit-box-shadow: 0 1px 3px rgb(18 18 18 / 10%);
          box-shadow: 0 1px 3px rgb(18 18 18 / 10%);
          background: #fff;

          .user-info {
            width: 100%;
            overflow: hidden;
            box-sizing: border-box;
            margin: 0px auto;
            padding: 0px;

            .item {
              width: 33%;
              overflow: hidden;
              float: left;
              margin: 0px auto;
              box-sizing: border-box;
              padding: 10px;
              cursor: pointer;
              text-align: center;
            }
          }

          .user-text {
            color: maroon;
            font-size: 14px;
            text-align: center;
            line-height: 1.22;
            margin: 12px 0;
            word-wrap: break-word;
            font-weight: 500;
            font-synthesis: style;
          }
        }

        .web_text {
          /**top:390px */
          width: 100%;
          overflow: hidden;
          margin: 10px auto;
          box-sizing: border-box;
          padding: 10px;
          color: rgb(132, 145, 165);
          -webkit-box-shadow: 0 1px 3px rgb(18 18 18 / 10%);
          box-shadow: 0 1px 3px rgb(18 18 18 / 10%);
          background: #fff;
        }

        .hot_lists {
          width: 100%;
          margin: 10px auto;
          border-radius: 2px;
          -webkit-box-shadow: 0 1px 3px rgb(18 18 18 / 10%);
          box-shadow: 0 1px 3px rgb(18 18 18 / 10%);
          background: #fff;
          font-size: $fontJ;
          box-sizing: border-box;
          padding: 10px;
          overflow: hidden;

          .hot_top_tites {
            width: 100%;
            overflow: hidden;
            margin: 0px auto;
            color: rgb(217, 83, 80);
            font-size: 14px;
          }
        }

        .login-centers {
          width: 100%;
          font-size: $fontJ;
          box-sizing: border-box;
          padding: 10px;
          border-radius: 2px;
          overflow: hidden;
          background: #fff;

          .login-title {
            width: 100%;
            overflow: hidden;
            text-align: left;
            line-height: 28px;
          }
        }
      }
    }

    .nav_centers {
      width: 980px;
      overflow: hidden;
      margin: 0px auto;
      line-height: 28px;
      border-bottom: 1px solid maroon;

      .nav_itemss {
        cursor: pointer;
        float: left;
        width: 38px;
        background: #fff;
        color: maroon;
        margin-left: 12px;
        text-align: center;
        overflow: hidden;
      }

      .nav_items {
        cursor: pointer;
        float: left;
        width: 38px;
        background: maroon;
        color: #fff;
        margin-left: 12px;
        text-align: center;
        overflow: hidden;
      }
    }
  }
}

@media (max-width: 1024px) and (min-width: 320px) {
  .index {
    width: 100%;
    overflow: hidden;
    margin: 0px auto;

    .el-row {
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .el-pagination {
      padding-top: 10px;
      padding-bottom: 10px;
    }

    .add-text {
      width: 100%;
      overflow: hidden;
      margin-top: 12px;
    }

    .movie-centers {
      width: 100%;
      overflow: hidden;
      padding: 0px;
      margin: 0px auto;
      box-sizing: border-box;

      .active {
        background: #e94608;
        color: #fff !important;
        border-right: 1px solid #fff;

      }

      .right-movie {
        float: right;
        width: 81%;
        overflow: hidden;
        padding: 0px;
        margin: 0px auto;
        box-sizing: border-box;
      }

      .movie-item {
        width: 19%;
        text-align: center;
        overflow: hidden;
        color: maroon;
        float: left;
        line-height: 32px;
        margin-top: 12px;
        overflow: hidden;
      }

      .movie-m {
        width: calc(25% - 1px);
        text-align: center;
        overflow: hidden;
        color: maroon;
        float: left;
        border-right: 1px solid #fff;
        line-height: 32px;
        margin-top: 12px;
      }
    }

    .sex-centers {
      width: 100%;
      overflow: hidden;
      padding: 0px;
      margin: 0px auto;
      box-sizing: border-box;

      .sex-item {
        width: 19%;
        color: maroon;
        overflow: hidden;
        line-height: 32px;
        text-align: center;
        float: left;
      }

      .active-m {
        background: url(../../public/imgs/man.png) no-repeat bottom #e94608 !important;
        background-size: 68px !important;
        color: #fff;
      }

      .active-n {
        background: url(../../public/imgs/nv.png) no-repeat bottom #e94608 !important;
        background-size: 68px !important;
        color: #fff;
      }

      .sex-m {
        width: 88px;
        text-align: center;
        overflow: hidden;
        height: 88px;
        background: url(../../public/imgs/man.png) no-repeat bottom;
        background-size: 68px;
        float: left;

        border: 1px solid maroon;
        border-radius: 6px;
      }

      .sex-n {
        width: 88px;
        text-align: center;
        overflow: hidden;
        height: 88px;
        margin-left: 12px;
        background: url(../../public/imgs/nv.png) no-repeat bottom;
        background-size: 68px;
        float: left;
        border: 1px solid maroon;
        border-radius: 6px;
      }
    }

    .navCenters {
      width: 100%;
      overflow: hidden;
      box-sizing: border-box;
      padding: 0px;
      margin: 0px auto;

      .right-nav {
        float: right;
        width: 81%;
        overflow: hidden;
        padding: 0px;
        margin: 0px auto;
        box-sizing: border-box;
      }

      .nav-item {
        width: 19%;
        color: maroon;
        overflow: hidden;
        line-height: 32px;
        text-align: center;
        float: left;
      }

      .navItems {
        width: 25%;
        cursor: pointer;
        text-overflow: ellipsis;
        -webkit-line-clamp: 1;
        box-sizing: border-box;
        white-space: nowrap;
        -webkit-box-orient: vertical;
        color: maroon;
        overflow: hidden;
        line-height: 32px;
        text-align: center;
        float: left;
        border-radius: 8px;
        background: url(../../public/imgs/deletes.png) no-repeat right;
        background-size: 18px;
      }
    }

    .container {
      width: 100%;
      overflow: hidden;
      margin: 95px auto auto auto;
      padding: 0px;

      .new-font-search {
        width: 98%;
        overflow: hidden;
        margin: 8px auto;
        text-align: left;
        color: #072;
        font-size: 14px;

        .hot-search-items {
          background-color: #fff;
          border-color: #f5dab1;
          color: #e6a23c;
          width: 100%;
          padding: 0 10px;
          line-height: 30px;
          border-width: 1px;
          border-style: solid;
          box-sizing: border-box;
          position: relative;

          .close {
            position: absolute;
            bottom: 0px;
            right: 0px;
            width: 30px;
            height: 30px;
            background: url(../../public/imgs/deletes.png) no-repeat center;
            background-size: 25px 25px;
          }
        }
      }

      .center-font-search {
        width: 98%;
        overflow: hidden;
        margin: 10px auto auto auto;
        text-align: left;
        color: #072;
        font-size: 14px;
      }

      .search-centers {
        display: none;
      }

      .left {
        width: 99.99%;
        padding: 0px;
        overflow: hidden;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        margin: 0px auto;

        .el-page {
          width: 100%;
          overflow: hidden;
          padding: 0px;
          -webkit-box-sizing: border-box;
          box-sizing: border-box;
          margin: 0px auto;

          .left-page {
            width: 50%;
            float: left;
            overflow: hidden;
            box-sizing: border-box;
            padding: 0px;
          }

          .right-page {
            width: 50%;
            float: left;
            overflow: hidden;
            box-sizing: border-box;
            padding: 10px;
          }
        }

        .list {
          width: 100%;
          overflow: hidden;
          margin: 0px auto;


          .items {
            width: 100%;
            overflow: hidden;
            margin: 0px auto;
            border-top: 1px dashed #f0f2f7;
            background: #fff;
            box-sizing: border-box;
            padding: 10px;

            .item-contents {
              width: 100%;
              box-sizing: border-box;
              overflow: hidden;
              margin: 0px auto;
              padding: 0px;
            }

            .text-click {
              width: 100%;
              box-sizing: border-box;
              overflow: hidden;
              margin: 12px auto;
              background: #f1f2f3;
              position: relative;
              border-radius: 6px;
              padding: 12px;
            }

            .pic-centers {
              width: 100%;
              box-sizing: border-box;
              overflow: hidden;
              margin: 12px auto;
              padding: 0px;
              height: 128px;
            }

            .pic-contents {
              width: 100%;
              box-sizing: border-box;
              overflow: hidden;
              margin: 12px auto;
              padding: 0px;
            }

            .video-centers {
              width: 100%;
              box-sizing: border-box;
              overflow: hidden;
              //padding: 10px;
              margin: 12px auto;
            }

            .body-centers {
              width: 100%;
              overflow: hidden;
              margin: 0px auto 12px auto;
              padding: 0px;
            }

            .mores {
              width: 100%;
              overflow: hidden;
              box-sizing: border-box;
              padding: 0px;
              line-height: 28px;
              color: #37a;
            }

            .user-nickname {
              font-synthesis: style;
              color: #37a;
              padding-left: 10px;
              box-sizing: border-box;
              float: left;
              line-height: 28px;
              padding-right: 10px;
              overflow: hidden;
            }

            .focuscenter {
              float: left;
              color: #999;
              box-sizing: border-box;
              overflow: hidden;
            }

            .createtime {
              float: left;
              color: #999;
              font-size: 12px;
              line-height: 28px;
              box-sizing: border-box;
              overflow: hidden;
              padding-right: 10px;
              padding-left: 10px;
            }

            .center-tag {
              width: 100%;
              overflow: hidden;
              margin: 10px auto;

              .item-tags {
                margin-left: 8px;
              }

              .item-tags:nth-child(1) {
                margin-left: 0px;
              }
            }

            .items-title {
              color: #37a;
              font-weight: 500;
              font-synthesis: style;
              font-size: 16px;
              width: 100%;
              line-height: 28px;
              overflow: hidden;
              word-wrap: break-word; //自动换行
              word-break: break-all; //自动换行（兼容字母）
              text-overflow: ellipsis; //溢出显示省略号
              display: -webkit-box;
              -webkit-line-clamp: 1; //显示3行
              -webkit-box-orient: vertical;
            }

            .items-centers {
              width: 100%;
              overflow: hidden;
              margin: 0px auto;
              padding: 0px;

              .image-centers {
                width: 100%;
                overflow: hidden;
                cursor: pointer;
                padding: 0px;
                box-sizing: border-box;
                margin-top: 12px;
              }

              img {
                //float: left;
                width: 100%;
                overflow: hidden;
                text-align: center;
              }

              .text {
                // float: right;
                // width: calc(100% - 108px);
                width: 100%;
                overflow: hidden;
                margin: 0px auto;
                padding: 0px;

                .font-text {
                  color: #333;
                  font-size: 14px;
                  font-weight: 500;
                  font-synthesis: style;
                  cursor: pointer;
                  width: 100%;
                  overflow: hidden;
                  word-wrap: break-word; //自动换行
                  word-break: break-all; //自动换行（兼容字母）
                  overflow: hidden; //超出隐藏
                  text-overflow: ellipsis; //溢出显示省略号
                  display: -webkit-box;
                  -webkit-line-clamp: 1; //显示3行
                  -webkit-box-orient: vertical;
                }
              }
            }

            .packup {
              display: inline-block;
              height: auto;
              padding: 0;
              line-height: inherit;
              background-color: transparent;

              line-height: 28px;
              margin-left: 4px;
              color: #175199;
            }
          }
        }
      }

      .right {
        width: 96%;
        overflow: hidden;
        border-radius: 2px;
        -webkit-box-shadow: 0 1px 3px rgb(18 18 18 / 10%);
        box-shadow: 0 1px 3px rgb(18 18 18 / 10%);
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        padding: 10px;
        margin: 10px auto;

        .user-centers {
          width: 100%;
          overflow: hidden;
          box-sizing: border-box;
          margin: 10px auto;
          padding: 0px;
          border-radius: 2px;
          -webkit-box-shadow: 0 1px 3px rgb(18 18 18 / 10%);
          box-shadow: 0 1px 3px rgb(18 18 18 / 10%);
          background: #fff;

          .user-info {
            width: 100%;
            overflow: hidden;
            box-sizing: border-box;
            margin: 0px auto;
            padding: 0px;

            .item {
              width: 88px;
              overflow: hidden;
              float: left;
              margin: 0px auto;
              box-sizing: border-box;
              padding: 10px;
              text-align: center;
            }
          }

          .user-text {
            color: maroon;
            font-size: 14px;
            text-align: center;
            line-height: 1.22;
            margin: 12px 0;
            word-wrap: break-word;
            font-weight: 500;
            font-synthesis: style;
          }
        }

        .login-title {
          width: 100%;
          overflow: hidden;
          text-align: left;
          line-height: 30px;
        }

        .hot_lists {
          display: none;
        }

        .hot_centers {
          display: none;
        }

        .web_text {
          width: 100%;
          overflow: hidden;
          margin: 0px auto;

          .font-centers {
            width: 100%;
            overflow: hidden;
            margin: 10px auto;

            .font {
              width: 100%;
              overflow: hidden;
              line-height: 28px;
              box-sizing: border-box;
              color: #8590a6;
            }
          }

          .wxinfo {
            width: 288px;
            overflow: hidden;
          }
        }

        .hot_centers {
          display: none;
        }
      }
    }

    .nav-topbar {
      width: 100%;
      overflow: hidden;
      position: fixed;
      top: 0px;
      left: 0px;
      z-index: 10;
      background: #fff;

      .mobiles-nav-list {
        width: 100%;
        overflow: hidden;
        box-sizing: border-box;
        margin: 0px auto;

        .nav-list-centers {
          width: 100%;
          overflow: hidden;
          box-sizing: border-box;
          padding: 0px;
          margin: 0px auto;
        }

        .nav-list-scroll::-webkit-scrollbar {
          width: 4px;
          height: 4px;
          background-color: #faecd8;
        }

        .nav-list-scroll::-webkit-scrollbar-thumb {
          background-color: #e67843;
        }

        .nav-list-scroll {
          width: 100%;
          white-space: nowrap;
          overflow-y: auto;
          box-sizing: border-box;
          padding: 0px;
          margin: 0px auto;

          .active-font {
            // border-bottom: 1px solid #ff4b53;
            color: #ff4b53;
            font-weight: 600;

            .line {
              display: block !important;
              position: absolute;
              width: 38px;
              left: 15px;
              height: 2px;
              background: #ff4b53;
              bottom: 3px;
            }
          }

          .scroll-items {
            width: 68px;
            line-height: 45px;
            overflow: hidden;
            text-align: center;
            display: inline-block;
            position: relative;

            .line {
              display: none;
            }
          }
        }

        .nav-items:nth-child(1) {
          background: url(../../public/imgs/logos.png) no-repeat center;
          background-size: 30px;
          height: 45px;

        }

        .nav-items-close {
          width: 49px;
          line-height: 40px;
          font-size: 16px;
          overflow: hidden;
          text-align: center;
          display: block;
          float: left;
        }

        .nav-items {
          width: 68px;
          line-height: 45px;
          font-size: 16px;
          overflow: hidden;
          text-align: center;
          display: block;
          float: left;
        }
      }

      .nav-list {
        display: none;
      }
    }
  }
}
</style>